import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TripleVennDiagram = ({ headings }) => {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);
  const [showCombined, setShowCombined] = useState(false);

  const handleCircleClick = () => {
    setClicked(!clicked);
    setTimeout(() => {
      setShowCombined(!showCombined);
    }, 270);
  };

  return (
    <div className="venn-container">
      <div
        className={`circle circle1 ${clicked ? "clicked" : ""}`}
        onClick={handleCircleClick}>
        <h4>{t("AI")}</h4>
      </div>
      <div
        className={`circle circle2 ${clicked ? "clicked" : ""}`}
        onClick={handleCircleClick}>
        <h4>{t("Data Governance")}</h4>
      </div>
      <div
        className={`circle circle3 ${clicked ? "clicked" : ""}`}
        onClick={handleCircleClick}>
        <h4>{t("Data Science")}</h4>
      </div>
      {showCombined && (
        <div className="circle combined" onClick={handleCircleClick}>
          <ul style={{ listStyleType: "none", margin: "0", padding: "0" }}>
            {headings.map((heading, index) => (
              <li key={index} style={{ color: "white", textAlign: "center" }}>
                <h4>{t(heading)}</h4>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TripleVennDiagram;
