import { useMediaQuery } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function ProductsShowcase({ products }) {
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  return (
    <div
      style={{
        padding: "0",
        margin: "0",
        width: "100%",
      }}
    >
      <Row
        className="row-products-homepage"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {Array.isArray(products) &&
          products.map((product) => (
            <Col
              lg={6}
              md={8}
              sm={6}
              xs={6}
              key={product._id}
              style={{
                padding: isSmallScreen ? "1rem" : "2rem",
              }}
              className="product-container-homepage"
            >
              <div stlye={{ alignItems: "center", justifyContent: "center"}}>
                <Link
                  to={
                    product.url !== undefined &&
                    product.url !== "coming soon" &&
                    product.url !== null
                      ? `/products/${product.url}`
                      : null
                  }
                >
                  <div
                    style={{
                      borderRadius: "25px",
                      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                      margin: isSmallScreen ? "0.5rem" : "0.5rem",
                      border: "6px double black",
                      backgroundColor: "white",
                      width: "100%",
                      alignItems:"center", justifyContent:"center"
                    }}
                  >
                    <img
                      src={product.image}
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      className="product-img-showcase"
                    />
                  </div>
                </Link>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
}

export default ProductsShowcase;
