import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchProductData = createAsyncThunk(
  "productData/fetch",
  async (productName) => {
    try {
      const contextResponse = await axios.get(
        `/api/productContext/${productName}`
      );
      const featuresResponse = await axios.get(
        `/api/productFeatures/${productName}`
      );
      const contextData = contextResponse.data;
      const featuresData = featuresResponse.data;
      return { contextData, featuresData };
    } catch (error) {
      throw error;
    }
  }
);

const productContextSlice = createSlice({
  name: "productContext",
  initialState: {
    contextData: {},
    featuresData: [],
    selectedData: {},
    descSelectedData: [],
    loading: false,
    error: null,
  },
  reducers: {
    changeSelectedData: (state, action) => {
      state.selectedData = action.payload;
      const splitSections = (str) => str.split("--");
      state.descSelectedData = splitSections(state.selectedData.description);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductData.fulfilled, (state, action) => {
        state.contextData = action.payload.contextData;
        state.featuresData = action.payload.featuresData;
        state.selectedData =
          action.payload.featuresData[action.payload.featuresData.length - 1];
        const splitSections = (str) => str.split("--");
        state.descSelectedData = splitSections(state.selectedData.description);
        state.loading = false;
      })
      .addCase(fetchProductData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { changeSelectedData } = productContextSlice.actions;

export default productContextSlice.reducer;
