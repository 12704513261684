import { Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Bubbles from "./Bubbles";
import { useTranslation } from "react-i18next";
import HomeGraph from "./HomeGraph";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../feature/product/productSlice";
import ProductsShowcase from "./ProductsShowcase";

function Products() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width:767px)");
  const dispatch = useDispatch();
  const { data: products } = useSelector((state) => state.products);
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <div
      style={{
        padding: "0rem",
        margin: "0rem",
        width: "100%",
      }}>
      <Row className="generic-row-main" style={{justifyContent:"center", alignItems:"center"}}>
        <Col
          md={6}
          xs={isSmallScreen ? { order: 1 } : { order: 0 }}
          style={
            isSmallScreen
              ? {
                  padding: "0rem 0rem",
                }
              : {
                  paddingBottom: "0rem",
                }
          }>
          {products.length > 0 && <ProductsShowcase products={products} />}
        </Col>
        <Col md={6} xs={12} style={{justifyContent:"center", alignItems:"center"}}>
          <div className="product-text-section" style={{padding:"3.5rem", justifyContent:"center", alignItems:"center"}}>
            <Typography
              variant="h2"
              color="#ffffff"
              pb={2}
              fontWeight={"800"}
              fontSize={
                isSmallScreen ? "35px" : "47px"
              }>
              {t("Products")}
            </Typography>
            <Typography
              variant="overline"
              color="#ffffff"
              fontWeight={"bold"}
              fontSize={"15px"}
              letterSpacing={"2px"}>
              {t("Innovative Data Solutions")}
            </Typography>
            <Typography variant="h6" color="#ffffff" pt={8} fontSize={"20px"}>
              {t(
                "Unlock the full potential of your data with our innovative products. From advanced analytics tools to intelligent Al solutions, our customized products provide actionable insights and drive business value. Developed by our team of experts using cutting-edge technologies and industry best practices, our products help you improve decision-making, automate processes, and optimize performance. Transform your data into a strategic asset and gain a competitive advantage in your industry with our products."
              )}
            </Typography>
            <br />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Products;
