import React from "react";
import { Col, Row } from "react-bootstrap";
import TripleVennDiagram from "./TripleVennDiagram";
import "../../screens/HomeScreen.css";
import { Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

function DataToResults() {
  const { t } = useTranslation();
  const headings = ["AI", "Data Governance", "Data Science"];
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  return (
    <div style={{ padding: "1rem", margin: "0rem", width: "100%" }}>
      <Row
        className="generic-row-main"
        style={{
          ...(isSmallScreen
            ? {
                padding: "4rem 0rem 2rem 0rem",
              }
            : {
                padding: "3rem 0rem 3rem 0rem",
              }),
        }}
      >
        <Col md={5}>
          <Typography
            variant="h1"
            padding={2}
            color={"#ffffff"}
            fontWeight={"bold"}
            fontSize={
              isSmallScreen ? "35px" : "47px"
            }
          >
            {t("Transforming Data into Results")}
          </Typography>
          <Typography variant="overline" color={"#ffffff"} padding={2}>
            {t("Data-driven Solutions for Your Business")}
          </Typography>
          <br />
          <br />
          <Typography variant="h6" color={"#ffffff"} padding={2}>
            {t(
              "At Artificient, we transform complex data into clear, actionable insights that drive results for your business. Let us help you turn your data into success."
            )}
          </Typography>
          <br />
          <Typography variant="h6" color={"#ffffff"} padding={2}>
            {t(
              "Our expert team of data consultants provides custom-tailored solutions to help your business make informed decisions and achieve your goals."
            )}
          </Typography>
          <br />
          <Typography variant="h6" color={"#ffffff"} padding={2}>
            {t(
              "We specialize in empowering businesses of all sizes to leverage their data for better decision-making and growth. Let us help you unlock the full potential of your data."
            )}
          </Typography>
        </Col>
        <Col md={7}>
          <div className="vennContainer">
            <TripleVennDiagram headings={headings} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default DataToResults;
