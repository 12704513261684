import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchSearchResults = createAsyncThunk(
  "searchResults/fetch",
  async (searchQuery) => {
    await new Promise((resolve) => setTimeout(resolve, 900));
    const response = await axios.get(`/api/search/?query=${searchQuery}`);
    return response.data;
  }
);

const searchSlice = createSlice({
  name: "searchResults",
  initialState: {
    data: [],
    total: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchResults.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.total = Object.values(action.payload).reduce(
          (acc, arr) => acc + arr.length,
          0
        );
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default searchSlice.reducer;
