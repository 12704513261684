import React, { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";
import career from "../../assets/images/About-Career/career.webp";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import empty from "../../assets/images/About-Career/career-not-available.webp";

import JobCard from "./Career/JobCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs } from "../../feature/job/jobSlice";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

function AboutCareers() {
  const { t } = useTranslation();
  const departments = [
    "Engineering",
    "Data Science",
    "Marketing",
    "Product",
    "Sales",
    "Customer Support",
    "HR, Legal, Finance",
  ];
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState(departments[0]);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const dispatch = useDispatch();
  const { data: jobs, loading } = useSelector((state) => state.jobs);

  useEffect(() => {
    dispatch(fetchJobs());
  }, [dispatch]);

  function reformatString(str) {
    str = str.split("_").join(", ");
    str = str.replace(/([a-z])([A-Z])/g, "$1 $2");
    return str;
  }

  return (
    <div
      style={{
        margin: "0px",
        padding: "0px",
        width: "100%",
      }}>
      {loading && <Loader loading={loading} />}
      <Row className="generic-row-main" style={{ maxWidth: "100%" }}>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography
            align="center"
            color="#212165"
            variant={"h1"}
            fontSize={isSmallScreen ? 25 : 35}
            padding={"3rem"}
            fontWeight={"bold"}>
            {t("Join Our Team: Explore Our Job Vacancies and Apply Today")}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "1rem",
              marginRight: "0.5rem",
              paddingBottom: "2rem",
            }}>
            <img
              src={career}
              style={{
                margin: "5% 5% 5% 5%",
                maxWidth: "100%",
                objectFit: "contain",
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.4)",
              }}
            />
          </div>
        </Row>
        <Row
          style={{
            margin: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...(isSmallScreen
              ? { padding: "2rem 0.5rem 5rem 0.5rem" }
              : { padding: "2rem 4rem 6rem 4rem" }),
          }}>
          <Paper
            elevation={12}
            style={{ backgroundColor: "#f6f6f7", borderRadius: "50px" }}>
            <Typography
              align="left"
              color="#0a0a0a"
              variant={"h2"}
              padding={
                isSmallScreen ? "2rem 0rem 1rem 0rem" : "3rem 0rem 1rem 2rem"
              }
              fontSize={isSmallScreen ? 30 : 40}
              fontWeight={"800"}>
              {t("Career Opportunities")}
            </Typography>
            <Row>
              <Col md={5} style={{ margin: "0", padding: "0" }}>
                <div style={{ padding: "0rem 3rem 2rem 2.5rem" }}>
                  <FormControl sx={{ m: 1, minWidth: 250 }}>
                    <InputLabel id="location-selector">
                      {t("Location")}
                    </InputLabel>
                    <Select
                      labelId="location-selector"
                      id="demo-simple-select-helper"
                      value={location}
                      label={t("Location")}
                      onChange={(e) => {
                        setLocation(e.target.value);
                      }}>
                      <MenuItem value={""}>
                        <em>{t("All Locations")}</em>
                      </MenuItem>
                      <MenuItem value={"Remote"}>{t("Remote")}</MenuItem>
                      <MenuItem value={"Hybrid"}>{t("Hybrid")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div style={{ padding: "1rem 3rem 3rem 3rem" }}>
                  {departments.map((department) => {
                    return (
                      <Button
                        key={department}
                        style={{
                          padding: "0",
                          margin: "0",
                          display: "block",
                          marginBottom: "1rem",
                        }}
                        color={"info"}
                        onClick={() => setCategory(department)}>
                        <Typography
                          variant="h3"
                          fontSize={26}
                          color={
                            department === category ? "#000000" : "#888888"
                          }
                          align="left">
                          {t(department)}
                        </Typography>
                      </Button>
                    );
                  })}
                </div>
              </Col>
              <Col
                md={7}
                style={{
                  padding: "0rem 4rem 1rem 4rem",
                  marginTop: "0rem",
                  marginBottom: "3rem",
                }}
                className="scrollable-col">
                {jobs.length > 0 &&
                jobs
                  .filter((job) => location === "" || job.location === location)
                  .filter((job) => job.department === category).length > 0 ? (
                  jobs
                    .filter(
                      (job) => location === "" || job.location === location
                    )
                    .filter((job) => job.department === category)
                    .map((job) => {
                      return <JobCard job={job} key={job._id} />;
                    })
                ) : (
                  <div
                    className="no-careers-available"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      margin: "1rem 0.5rem 1rem 0.5rem",
                      height: "90%",
                    }}>
                    <Typography
                      color="#000"
                      variant="h4"
                      fontWeight={"bold"}
                      padding="0rem"
                      fontSize={20}>
                      {t("No available career opportunities at the moment in")}{" "}
                      {t(reformatString(category))}
                    </Typography>
                    <img
                      src={empty}
                      className="no-careers-available-photo"
                      style={{
                        maxWidth: "80%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Paper>
        </Row>
      </Row>
    </div>
  );
}

export default AboutCareers;
