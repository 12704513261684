import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { LinkContainer } from "react-router-bootstrap";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../../feature/news/newsSlice";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";

function LatestNews() {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.news);
  const { t, i18n } = useTranslation();
  const [latestPosts, setLatestPosts] = useState("");
  const [bigPost, setBigPost] = useState("");
  const navigate = useNavigate("");

  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isSmallScreen = useMediaQuery("(max-width:767px)");

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const date = new Date(dateString);
    const month = t(date.toLocaleString("default", { month: "long" }));
    const formattedDate = date.toLocaleDateString(undefined, options);

    return formattedDate.replace(
      date.toLocaleDateString("default", { month: "long" }),
      month
    );
  }

  useEffect(() => {
    dispatch(fetchNews());
  }, [dispatch]);

  useEffect(() => {
    if (
      data !== undefined &&
      data !== null &&
      data !== [] &&
      data !== "" &&
      data.length !== 0
    ) {
      const allNewsArray = Object.values(data);
      const topPost = allNewsArray
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 1);

      setBigPost(topPost);

      const latest2Posts = allNewsArray
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(1, 3);

      setLatestPosts(latest2Posts);
    }
  }, [data]);

  return (
    <div style={{ padding: "0rem", margin: "0rem", width: "100%" }}>
      <Row className="generic-row-main">
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: isSmallScreen ? "start" : "center",
            marginBottom: "6rem",
          }}>
          <Typography variant="h2" color={"white"} fontSize={
              isSmallScreen ? "35px" : "47px"
            }>
            {t("Our News")}
          </Typography>
          <Typography variant="h5" color={"white"} fontSize={
              isSmallScreen ? "16px" : "24px"
            }>
            {t("The latest news from the world of data & Artificient")}
          </Typography>
        </Box>

        <Col
          md={8}
          style={{
            margin: "0",
            marginBottom: "1rem",
            padding: "0",
          }}>
          {bigPost !== undefined && bigPost !== null && bigPost !== "" ? (
            <LinkContainer to={`/news/${bigPost[0]?._id}`}>
              <Row className="row-clickable">
                <Col md={7} style={{ margin: 0, padding: 0 }}>
                  <div
                    style={{
                      backgroundImage: `url(${bigPost[0]?.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      ...(isSmallScreen
                        ? {
                            borderTopLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "0",
                            borderBottomLeftRadius: "0",
                            height: "350px",
                          }
                        : {
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            height: "740px",
                          }),
                    }}></div>
                </Col>
                <Col
                  md={5}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    border: "0.5px solid white",

                    ...(isSmallScreen
                      ? {
                          borderBottomRightRadius: "25px",
                          borderBottomLeftRadius: "25px",
                          borderTop: "none",
                          borderLeft: "0.5px solid white",
                        }
                      : {
                          borderTopRightRadius: "25px",
                          borderBottomRightRadius: "25px",
                          borderLeft: "none",
                          borderTop: "0.5px solid white",
                        }),
                  }}>
                  <Typography
                    variant="h3"
                    fontSize={40}
                    color="white"
                    className="big-post-title"
                    style={{ textAlign: "left" }}
                    marginBottom={3}>
                    {bigPost && bigPost[0][i18n.language + "Title"]}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="white"
                    className="big-post-small-description"
                    fontSize={24}
                    style={{ textAlign: "left" }}
                    marginBottom={4}>
                    {bigPost && bigPost[0][i18n.language + "SmallDescription"]}
                  </Typography>
                  <br />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}>
                    <Typography
                      variant="overline"
                      color="white"
                      margin={"1rem 0rem 0rem 0rem"}>
                      {bigPost !== "" ? formatDate(bigPost[0]?.createdAt) : ""}
                    </Typography>
                    <ArrowCircleRightIcon
                      style={{
                        fontSize: 50,
                        color: "white",
                        margin: "1rem 0rem 1rem 0rem",
                        padding: "0px",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </LinkContainer>
          ) : (
            <></>
          )}
        </Col>
        <Col md={4} className="column-latest-news-small-posts">
          {latestPosts !== "" &&
            latestPosts !== undefined &&
            Object.values(latestPosts)?.map((post) => (
              <Row
                className="row-clickable"
                key={nanoid()}
                style={{
                  height: "360px",
                  marginLeft: "1rem",
                  marginBottom: "20px",
                  borderRadius: "25px",
                  backgroundImage: `url(${post?.image})`,

                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                }}>
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                  <LinkContainer
                    to={`/news/${post?._id}`}
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                    }}>
                    <div className="small-post-news-section">
                      <Typography
                        variant="h6"
                        color="white"
                        fontWeight={"bold"}
                        style={{
                          display: "inline",
                        }}>
                        {post && post[i18n.language + "Title"]}
                      </Typography>
                      <br />
                      <div
                        className="date-arrow-small-post"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}>
                        <Typography
                          variant="overline"
                          color="white"
                          fontWeight={"bold"}
                          style={{
                            display: "inline",
                          }}>
                          {formatDate(post?.createdAt)}
                        </Typography>
                        <ArrowCircleRightIcon
                          style={{
                            fontSize: 40,
                            color: "white",
                            margin: "0rem 1.5rem 1rem 0rem",
                            padding: "0px",
                          }}
                        />
                      </div>
                    </div>
                  </LinkContainer>
                </div>
              </Row>
            ))}
        </Col>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "2rem",
          }}>
          <button className="navigate-news" onClick={() => navigate("/news")}>
            {t("ALL NEWS")}
          </button>
        </div>
      </Row>
    </div>
  );
}

export default LatestNews;
