import React from "react";
import { Typography, useMediaQuery } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
function Banner() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const redirectVirtualOffices = () => {
    ReactGA.initialize("G-ZNBTFWN36W");
    ReactGA.event({
      category: "Buttons",
      action: "Banner Click",
      label: "Virtual Offices",
      value: 1,
    });
    navigate("/about/company/showcase");
  };

  return (
    <div className="banner-main">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <div
          onClick={() => redirectVirtualOffices()}
          className="banner-navigate">
          <Typography variant="h4" fontSize={isSmallScreen ? 13 : 16}>
            {t("EXPLORE OUR VIRTUAL OFFICES NOW!")}
            <ArrowForwardIcon
              style={{
                fontSize: isSmallScreen ? 16 : 20,
                color: "white",
                paddingBottom: "2px",
                marginLeft: isSmallScreen ? "0px" : "5px",
              }}
            />
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Banner;
