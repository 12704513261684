import { Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";
import EastIcon from "@mui/icons-material/East";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";

function JobCard({ job }) {
  const { t } = useTranslation();
  return (
    <div key={job._id} style={{ paddingBottom: "2em" }}>
      <LinkContainer to={`/about/careers/${job._id}`} className="link-job">
        <Card
          className="job-box"
          sx={{
            boxShadow: 0,
            borderRadius: 0,
            padding: "1rem",
          }}>
          <CardContent>
            <Typography color="#000" variant="h5" fontWeight={"bold"}>
              {t(job.name)}
            </Typography>
            <Typography
              color="#000"
              variant="h6"
              fontWeight={"bold"}
              paddingBottom={"1rem"}>
              {t(job.location)}
            </Typography>
            <Typography color="#000" variant="body1">
              {t(job.smallDesc)}
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              justifyContent: "flex-end",
              paddingRight: "2rem",
              alignItems: "center",
            }}>
            <EastIcon
              className="arrow-icon-career"
              style={{
                fontSize: 50,
                color: "black",
                margin: "0",
                padding: "0px",
              }}
            />
          </CardActions>
        </Card>
      </LinkContainer>
    </div>
  );
}

export default JobCard;
