import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "boxicons/css/boxicons.min.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { submitContactUs } from "../feature/contact/contactUsSlice";

function ContactUs(props) {
  const dispatch = useDispatch();
  const { isLoading, success, error, message } = useSelector(
    (state) => state.contactUs
  );
  const { t } = useTranslation();
  const [contactForm, setContactForm] = useState({
    fullName: "",
    companyName: "",
    emailAddress: "",
    phoneNumber: "",
    message: "",
  });

  const setDefaultValues = () => {
    setContactForm({
      fullName: "",
      companyName: "",
      emailAddress: "",
      phoneNumber: "",
      message: "",
    });
  };

  const submit = (e) => {
    e.preventDefault();
    dispatch(submitContactUs(contactForm));
  };
  useEffect(() => {
    if (success && !isLoading) {
      if (message == "Success") {
        toast.success(
          t(
            `Thank you for your email! We will contact you as soon as possible.`
          ),
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        setDefaultValues();
      } else {
        toast.error(t("Something went wrong, please check your inputs"), {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [dispatch, success]);

  const textareaRef = useRef(null);

  useEffect(() => {
    adjustIconHeight();
    window.addEventListener("resize", adjustIconHeight);
    return () => {
      window.removeEventListener("resize", adjustIconHeight);
    };
  }, []);

  const adjustIconHeight = () => {
    if (textareaRef.current) {
      const textareaHeight = textareaRef.current.scrollHeight;
      const iconSpan = document.getElementById("basic-icon-default-message2");
      if (iconSpan) {
        iconSpan.style.height = `${textareaHeight}px`;
      }
    }
  };

  return (
    <div className="container-xxl my-4">
      <ToastContainer />
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <h1 className="mb-2 contact-title" style={{ fontSize: "28px" }}>
                {t("Contact us")}
              </h1>
              <h2
                className="mb-4 contact-description"
                style={{ fontSize: "16px" }}>
                {t(
                  "We would love to hear from you. Please fill out the form below to get in touch with us."
                )}
              </h2>

              <div id="formAuthentication" className="mb-3" method="POST">
                <form onSubmit={submit}>
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-icon-default-fullname">
                      {t("Full Name")}
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text">
                        <i className="bx bx-user"></i>
                      </span>
                      <input
                        required
                        type="text"
                        className="form-control"
                        id="basic-icon-default-fullname"
                        placeholder={t("Full Name")}
                        style={{ backgroundColor: "#e9e9e9" }}
                        aria-describedby="basic-icon-default-fullname2"
                        value={contactForm.fullName}
                        onChange={(e) => {
                          setContactForm((prevState) => {
                            return {
                              ...prevState,
                              fullName: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-icon-default-company">
                      {t("Company")}
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-company2"
                        className="input-group-text">
                        <i className="bx bx-buildings"></i>
                      </span>
                      <input
                        type="text"
                        id="basic-icon-default-company"
                        className="form-control"
                        placeholder={t("Company")}
                        style={{ backgroundColor: "#e9e9e9" }}
                        aria-describedby="basic-icon-default-company2"
                        value={contactForm.companyName}
                        onChange={(e) => {
                          setContactForm((prevState) => {
                            return {
                              ...prevState,
                              companyName: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-icon-default-email">
                      {t("Email")}
                    </label>
                    <div className="input-group input-group-merge">
                      <span className="input-group-text">
                        <i className="bx bx-envelope"></i>
                      </span>
                      <input
                        required
                        type="email"
                        id="basic-icon-default-email"
                        style={{ backgroundColor: "#e9e9e9" }}
                        className="form-control"
                        placeholder={t("Email")}
                        aria-describedby="basic-icon-default-email2"
                        value={contactForm.emailAddress}
                        onChange={(e) => {
                          setContactForm((prevState) => {
                            return {
                              ...prevState,
                              emailAddress: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="form-text"></div>
                  </div>
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="basic-icon-default-phone">
                      {t("Phone No")}
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-phone2"
                        className="input-group-text">
                        <i className="bx bx-phone"></i>
                      </span>
                      <input
                        type="text"
                        id="basic-icon-default-phone"
                        className="form-control phone-mask"
                        style={{ backgroundColor: "#e9e9e9" }}
                        placeholder={t("Phone")}
                        aria-describedby="basic-icon-default-phone2"
                        value={contactForm.phoneNumber}
                        onChange={(e) => {
                          setContactForm((prevState) => {
                            return {
                              ...prevState,
                              phoneNumber: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="mb-3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <label
                      className="form-label"
                      htmlFor="basic-icon-default-message">
                      {t("Message")}
                    </label>
                    <div
                      className="input-group input-group-merge"
                      style={{ flex: 1, display: "flex" }}>
                      <span
                        id="basic-icon-default-message2"
                        className="input-group-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          minHeight: "7vh",
                        }}>
                        <i className="bx bx-comment"></i>
                      </span>
                      <textarea
                        ref={textareaRef}
                        required
                        id="basic-icon-default-message"
                        className="form-control"
                        placeholder={t("Your message")}
                        style={{
                          backgroundColor: "#e9e9e9",
                          flexGrow: 1,
                          resize: "none",
                          border: "none",
                          overflow: "hidden",
                          minHeight: "7vh",
                        }}
                        value={contactForm.message}
                        onChange={(e) => {
                          setContactForm((prevState) => {
                            return {
                              ...prevState,
                              message: e.target.value,
                            };
                          });
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                          adjustIconHeight();
                        }}></textarea>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{ backgroundColor: "#fc2b2d" }}
                    onSubmit={submit}>
                    {t("Send")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
