import { IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleNew } from "../feature/news/newsSlice";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";

function NewPage() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    singleNew: singleNew,
    loading,
    error,
  } = useSelector((state) => state.news);

  useEffect(() => {
    if (id !== null && id !== undefined) {
      dispatch(fetchSingleNew(id));
    }
  }, [dispatch, id]);
  if (error) {
    navigate("*");
  }

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const date = new Date(dateString);
    const month = t(date.toLocaleString("default", { month: "long" }));
    const formattedDate = date.toLocaleDateString(undefined, options);

    return formattedDate.replace(
      date.toLocaleDateString("default", { month: "long" }),
      month
    );
  }

  const handleLinkedInShare = () => {
    const url = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
      window.location.origin + location.pathname
    )}`;
    window.open(url);
  };

  const handleFacebookShare = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      window.location.origin + location.pathname
    )}`;
    window.open(url);
  };

  return (
    <div
      className="news-page"
      style={{ padding: "0rem", margin: "0rem", width: "100%" }}>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Row
          style={{
            padding: "0rem",
            margin: "0rem",
            width: "100%",
          }}>
          <Row
            style={{
              padding: "0rem",
              margin: "0rem",
              width: "100%",
              backgroundImage: `url(${singleNew?.image})`,
            }}>
            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
              <div
                className="generic-row-main"
                style={{
                  width: "fit-content",
                }}>
                <Typography
                  align="left"
                  color="#ffffff"
                  variant={"h5"}
                  padding={isSmallScreen ? "3rem 1rem" : "3rem"}
                  fontSize={"18xp"}
                  fontWeight={"bold"}>
                  {t(singleNew?.category)}
                </Typography>
                <Typography
                  align="left"
                  color="#ffffff"
                  variant={"h2"}
                  padding={isSmallScreen ? "3rem 1rem" : "3rem"}
                  fontSize={isSmallScreen ? "35px" : "50px"}
                  fontWeight={"bold"}>
                  {singleNew && singleNew[i18n.language + "Title"]}
                </Typography>
                <Typography
                  align="left"
                  color="#ffffff"
                  variant={"h2"}
                  padding={isSmallScreen ? "3rem 1rem" : "3rem"}
                  fontSize={"16px"}>
                  {t(formatDate(singleNew?.createdAt))}
                </Typography>
              </div>
            </div>
          </Row>
          <Row className="generic-row-main" style={{ width: "fit-content" }}>
            <Col md={8} sm={9}>
              <div>
                <Typography
                  align="left"
                  color="#000"
                  variant={"h3"}
                  padding={
                    isSmallScreen
                      ? "4rem 0.5rem 0rem 0.5rem"
                      : "5rem 3rem 4rem 1rem"
                  }
                  fontSize={"24px"}>
                  {singleNew &&
                    singleNew[i18n.language + "Description"]
                      ?.split("\r\n")
                      .map((paragraph, index) => (
                        <React.Fragment key={index}>
                          {paragraph}
                          <br />
                        </React.Fragment>
                      ))}
                </Typography>
              </div>
            </Col>
            <Col md={4} sm={3}>
              <div
                style={{
                  margin: isSmallScreen
                    ? "2rem 0.5rem 4rem 0.5rem"
                    : "4rem 2rem",
                }}>
                <Typography
                  align="left"
                  color="#000"
                  variant={"h4"}
                  padding={"2rem 0rem 0.5rem 0rem"}
                  fontWeight={"bold"}
                  fontSize={"20px"}>
                  {t("Share")}
                </Typography>
                <Row style={{ paddingLeft: "0.25rem" }}>
                  <IconButton
                    style={{ padding: "0", margin: "0", width: "max-content" }}
                    onClick={handleLinkedInShare}>
                    <LinkedInIcon
                      style={{
                        fontSize: 60,
                        color: "black",
                        margin: "0",
                        padding: "0",
                      }}
                    />
                  </IconButton>

                  <IconButton
                    style={{ padding: "0", margin: "0", width: "max-content" }}
                    onClick={handleFacebookShare}>
                    <FacebookIcon
                      style={{
                        fontSize: 60,
                        color: "black",
                        margin: "0",
                        padding: "0",
                      }}
                    />
                  </IconButton>
                </Row>
              </div>
            </Col>
          </Row>
        </Row>
      )}
    </div>
  );
}

export default NewPage;
