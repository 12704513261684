import React, { useEffect, useRef, useState } from "react";

import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import {
  playVideo,
  pauseVideo,
  hideVideo,
  appearVideo,
  fetchSplashScreenIOSVideo,
} from "../feature/splashScreen/splashScreenSlice";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

function SplashScreen() {
  const [cookies, setCookie] = useCookies(["playedVideo"]);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const dispatch = useDispatch();
  const { videoURL } = useSelector((state) => state.video);
  const navigate = useNavigate();
  const playerRef = useRef(null);
  const [play, setPlay] = useState(false);

  const handlePause = () => {
    if (cookies.playedVideo === undefined || !cookies.playedVideo) {
      setCookie("playedVideo", true, { path: "/", maxAge: 86400 });
    }
    navigate("/");
  };
  useEffect(() => {
    if (isIOS) {
      dispatch(fetchSplashScreenIOSVideo("splash"));
    }
    setPlay(true);
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
        width: "100%",
        height: "100%",
        padding: 0,
        margin: 0,
        overflow: "hidden",
        backgroundColor: "#000000",
      }}>
      {}
      <ReactPlayer
        ref={playerRef}
        className="video-player"
        url={videoURL}
        playing={play}
        width="100%"
        height="100%"
        volume={1}
        muted={true}
        controls={false}
        playbackRate={1.75}
        onEnded={handlePause}
        style={{ objectFit: "cover" }}
        playsinline={true}
        webkit-playsinline="true"
      />
    </div>
  );
}

export default SplashScreen;
