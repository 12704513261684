import React from "react";
import { Row } from "react-bootstrap";
import { Typography, useMediaQuery } from "@mui/material";
import offices from "../../../assets/images/About-Company/3.webp";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function OurOffices() {
  const isLargeScreen = useMediaQuery("(min-width:1500px)");
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: "0rem",
        margin: "0rem",
        width: "100%",
        backgroundColor: "#6f6f70",
        padding: "3rem 0rem",
      }}>
      <Row
        className="generic-row-main"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Row style={{ width: "100%" }}>
          <Typography
            color="white"
            variant={isLargeScreen ? "h2" : "h3"}
            align={"center"}
            fontWeight={700}
            fontSize={isLargeScreen ? "35px" : isSmallScreen ? "25px" : "30px"}>
            {t(
              "Immerse yourself in a 3D realm of our company's offices to explore our services and products  Give it a try today!"
            )}
          </Typography>
        </Row>
        <Row style={{ padding: "2rem 4rem 2rem 4rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <button
              className="btn-offices"
              onClick={() => navigate("/about/company/showcase")}>
              <span> ARTi {t("Showcase")}</span>
            </button>
          </div>
        </Row>
        <Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0rem 1rem 0rem 1rem",
            }}>
            <img
              src={offices}
              style={{
                maxWidth: "100%",
                borderRadius: "25px",
                height: "auto",
                margin: "1rem 2rem 1rem 0rem",
                objectFit: "contain",
              }}
            />
          </div>
        </Row>
      </Row>
    </div>
  );
}

export default OurOffices;
