import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import AboutCompany from "./screens/About/AboutCompany";
import AboutCareers from "./screens/About/AboutCareers";
import AboutTeam from "./screens/About/AboutTeam";
import SplashScreen from "./components/SplashScreen";
import "mdb-react-ui-kit/dist/css/mdb.min.css";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import PageNotFound from "./screens/PageNotFound";
import ContactUs from "./screens/ContactUs";
import { appearVideo } from "./feature/splashScreen/splashScreenSlice";
import Services from "./screens/Services";
import Loader from "./components/Loader";
import Job from "./screens/About/Career/Job";
import News from "./screens/News";
import NewPage from "./screens/NewPage";
import Overlay from "./screens/Overlay";

import Product from "./screens/Products/Product";
import Service from "./screens/Services/Service";
import ScrollToTop from "./components/ScrollToTop";
import SearchResult from "./components/SearchResult";
import Banner from "./components/Banner";
import Showcase from "./screens/About/Company/Showcase";
import { useCookies } from "react-cookie";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CookieConsent from "./components/CookieConsent";
import CookiePolicy from "./screens/CookiePolicy";

function App() {
  const { activeDropdown } = useSelector((state) => state.dropdowns);
  const [cookies] = useCookies(["cookieConsent"]);

  return (
    <Router>
      {/* <Loader /> */}
      <ScrollToTop />
      <Header />
      {activeDropdown !== "" && activeDropdown !== undefined ? (
        <Overlay />
      ) : (
        <></>
      )}
      <Banner />
      {!cookies.cookieConsent && <CookieConsent />}
      <Routes>
        <Route path="/" element={<HomeScreen />} exact />

        <Route path="/about/company" element={<AboutCompany />} />
        <Route path="/about/team" element={<AboutTeam />} />
        <Route path="/about/careers" element={<AboutCareers />} />
        <Route path="/about/careers/:id" element={<Job />} />
        <Route path="/products/:productName" element={<Product />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:serviceName" element={<Service />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<NewPage />} />
        <Route path="/results/:searchQuery" element={<SearchResult />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about/company/showcase" element={<Showcase />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
