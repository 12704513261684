import { Card, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { nanoid } from "nanoid";
import { LinkContainer } from "react-router-bootstrap";
import EastIcon from "@mui/icons-material/East";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchResults } from "../feature/search/searchSlice";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

function SearchResult() {
  const { searchQuery } = useParams();
  const { t, i18n } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const dispatch = useDispatch();
  const {
    loading,
    data: searchResults,
    total: sum,
    error,
  } = useSelector((state) => state.search);

  useEffect(() => {
    dispatch(fetchSearchResults(searchQuery));
  }, [dispatch, searchQuery]);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  function convertToLowercase(str) {
    return str.toLowerCase();
  }
  return (
    <div className="all-search-results">
      <div
        className="big-row"
        style={{
          width: "100%",
          backgroundColor: "#fefefe",
        }}>
        {loading ? (
          <div style={{ minHeight: "850px" }}>
            <Loader />
          </div>
        ) : (
          <div>
            <Row
              style={{
                maxWidth: "1500px",
                margin: "auto",
              }}>
              <div>
                <Typography
                  align="left"
                  color="#000"
                  variant={"h2"}
                  fontSize={isSmallScreen ? 35 : 60}
                  padding={"5rem 3rem 1rem 3rem"}
                  fontWeight={"bold"}>
                  {t("Search results")}
                </Typography>
                <Typography
                  align="left"
                  color="#000"
                  variant={"h5"}
                  padding={"1rem 3rem 3rem 3rem"}
                  fontWeight={"bold"}>
                  {t("We found")} {sum} {t("results for")} "{searchQuery}"
                </Typography>
              </div>
            </Row>
            <div style={{ padding: "3rem 0rem" }}>
              {Object.entries(searchResults).map(([key, value]) => {
                if (!value || value.length === 0) {
                  return null;
                }

                return (
                  <Row
                    style={{
                      padding: "1rem 3rem 3rem 3rem",
                      borderTop: "2px solid #D3D3D3",
                    }}
                    key={key}>
                    <Row
                      style={{
                        maxWidth: "1500px",
                        margin: "auto",
                      }}>
                      <Typography
                        align="left"
                        color="#000"
                        variant={"h2"}
                        fontSize={isSmallScreen ? 35 : 50}
                        padding={"3rem"}
                        fontWeight={"bold"}>
                        {t(key)}
                      </Typography>
                      {Array.isArray(value) ? (
                        value?.map((singleResult, index) => (
                          <Col
                            lg={4}
                            md={6}
                            xs={12}
                            key={index}
                            style={{ padding: "1rem" }}>
                            <Card
                              key={nanoid()}
                              style={{
                                maxHeight: "620px",

                                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                              }}>
                              <LinkContainer
                                to={
                                  key === "News"
                                    ? `/${convertToLowercase(key)}/${
                                        singleResult._id
                                      }`
                                    : `/${convertToLowercase(key)}/${
                                        singleResult.url
                                      }`
                                }
                                className="link-new">
                                <div>
                                  {singleResult.image !== undefined && (
                                    <Row
                                      style={{
                                        maxHeight: "40%",
                                        height: "40%",
                                      }}>
                                      <div
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}>
                                        <img
                                          src={`${singleResult.image}`}
                                          alt={`${singleResult}'s Image`}
                                          style={{
                                            maxHeight: "100%",
                                            maxWidth: "100%",
                                            objectFit: "contain",
                                          }}
                                        />
                                      </div>
                                    </Row>
                                  )}
                                  {key === "News" && (
                                    <Typography
                                      align="left"
                                      color="#808080"
                                      variant={"h6"}
                                      padding={"2rem 3rem 1rem 3rem"}>
                                      {t(formatDate(singleResult.createdAt))}
                                    </Typography>
                                  )}

                                  <Typography
                                    align={key === "News" ? "left" : "center"}
                                    color="#000"
                                    variant={"h3"}
                                    fontSize={isSmallScreen ? 20 : 30}
                                    padding={
                                      key === "News"
                                        ? "1rem 3rem 1rem 3rem"
                                        : "2rem 3rem 2rem 3rem"
                                    }
                                    fontWeight={"bold"}
                                    minHeight={
                                      key === "News"
                                        ? "100%"
                                        : key === "Services"
                                        ? "134px"
                                        : "min-content"
                                    }
                                    maxHeight={"205px"}>
                                    {key === "News"
                                      ? singleResult[i18n.language + "Title"]
                                      : t(singleResult.name)}
                                  </Typography>

                                  <Row
                                    style={{
                                      paddingBottom: "2rem",
                                    }}>
                                    <Col>
                                      <Typography
                                        align="left"
                                        color="#000"
                                        fontSize={10}
                                        variant={"overline"}
                                        padding={"0rem 0rem 0rem 3rem"}
                                        fontWeight={"bold"}>
                                        {t(singleResult.category)}
                                      </Typography>
                                    </Col>
                                    <Col
                                      style={{
                                        display: "flex",
                                        justifyContent: "right",
                                        paddingRight: "4rem",
                                      }}>
                                      <Typography
                                        align="left"
                                        color="#000"
                                        variant={"overline"}
                                        padding={"0.3rem 0.5rem 0rem 0rem"}
                                        fontWeight={"bold"}>
                                        {t("Read More")}
                                      </Typography>
                                      <EastIcon
                                        style={{
                                          fontSize: 40,
                                          color: "black",
                                          margin: "0",
                                          padding: "0px",
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </LinkContainer>
                            </Card>
                          </Col>
                        ))
                      ) : (
                        <Typography
                          align="center"
                          color="#ffffff"
                          variant={"h2"}
                          padding={"3rem 3rem 3rem 3rem"}>
                          {t(
                            `No search results were found for these keywords. Please try again.`
                          )}
                        </Typography>
                      )}
                    </Row>
                  </Row>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchResult;
