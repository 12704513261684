import React from "react";

import PropTypes from "prop-types";
import { Typography, useMediaQuery } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import commitment from "../../../assets/images/About-Company/1.webp";
import { useTranslation } from "react-i18next";

function OurCommitment() {
  const isLargeScreen = useMediaQuery("(min-width:1500px)");
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();

  return (
    <div
      style={{
        padding: "0rem",
        margin: "0rem",
        width: "100%",
        padding: "3rem 0rem 6rem 0rem",
      }}>
      <Row
        className="generic-row-main"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Row className="section1-about-company">
          <Row
            style={{
              padding: isSmallScreen
                ? "0rem 0.5rem 1rem 0.5rem"
                : "3rem 1rem 1rem 1rem",
            }}>
            <Typography
              variant={isLargeScreen ? "h1" : "h2"}
              color="black"
              align="center"
              fontWeight={"800"}
              fontSize={
                isLargeScreen ? "35px" : isSmallScreen ? "25px" : "30px"
              }>
              {t("Empowering the future with Data and AI:")}
            </Typography>
            <Typography
              variant={isLargeScreen ? "h2" : "h3"}
              color="black"
              align="center"
              fontWeight={"800"}
              fontSize={
                isLargeScreen ? "35px" : isSmallScreen ? "25px" : "30px"
              }>
              {t("Our Commitment to Progress")}
            </Typography>
            <Typography
              variant={isLargeScreen ? "h5" : "h5"}
              color="black"
              align="center"
              fontWeight={"bold"}
              paddingTop={"1rem"}
              fontSize={
                isLargeScreen ? "25px" : isSmallScreen ? "16px" : "20px"
              }>
              {t(
                "Maximizing Your Business's Potential with Innovative Data and AI Strategies"
              )}
            </Typography>
          </Row>
          <Row>
            <Col md={4} style={{ padding: "3rem 1rem" }}>
              <img
                src={commitment}
                width={"100%"}
                style={{ borderRadius: "25px" }}
              />
            </Col>
            <Col
              md={8}
              style={{
                padding: "1rem 1rem 1rem 1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                variant="body1"
                color="black"
                letterSpacing={1.5}
                fontSize={isLargeScreen ? 20 : 16}>
                {t(
                  "In today's digital age, companies and organizations collect vast amounts of data from various sources, including customer interactions, sales transactions, and website activity. By making use of this data, businesses can gain valuable insights into their operations, customers, and markets. They can use these insights to make data-driven decisions that improve their products and services, enhance customer experiences, optimize operations, and drive growth. Failure to leverage this data puts companies at a significant disadvantage in an increasingly competitive landscape. Therefore, it is crucial for companies and organizations to invest in the resources and expertise necessary to collect, store, and analyze their data effectively, in order to gain a competitive advantage and achieve long-term success."
                )}
              </Typography>
            </Col>
          </Row>
        </Row>
      </Row>
    </div>
  );
}

export default OurCommitment;
