import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchNews = createAsyncThunk("news/fetch", async () => {
  await new Promise((resolve) => setTimeout(resolve, 900));
  const response = await axios.get(`/api/news/`);
  return response.data;
});

export const fetchSingleNew = createAsyncThunk(
  "news/fetchSingleNew",
  async (id) => {
    if (id !== undefined && id !== null) {
      const response = await axios.get(`/api/new/${id}`);
      return response.data;
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState: {
    data: [],
    singleNew: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSingleNew.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.singleNew = null;
      })
      .addCase(fetchSingleNew.fulfilled, (state, action) => {
        state.loading = false;
        state.singleNew = action.payload;
      })
      .addCase(fetchSingleNew.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default newsSlice.reducer;
