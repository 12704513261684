import { Typography, useMediaQuery } from "@mui/material";
import React from "react";
import mission from "../../../assets/images/About-Company/2.webp";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function OurMission() {
  const isLargeScreen = useMediaQuery("(min-width:1500px)");
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();

  return (
    <div
      style={{
        padding: "0rem",
        margin: "0rem",
        width: "100%",
        backgroundColor: "#242425",
        padding: "3rem 0rem 6rem 0rem",
      }}>
      <Row
        className="generic-row-main"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Row style={{ padding: "1rem" }}>
          <Typography
            variant={isLargeScreen ? "h2" : "h3"}
            color="white"
            fontWeight={"bold"}
            align={"center"}
            fontSize={isLargeScreen ? "35px" : isSmallScreen ? "25px" : "30px"}>
            {t("Our Mission")}
          </Typography>
        </Row>
        <Row style={{ padding: "2rem" }}>
          <Col
            md={6}
            style={{
              padding: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography
              variant="body1"
              letterSpacing={1.5}
              fontSize={isLargeScreen ? 20 : 16}>
              {t(
                "At Artificient, we are dedicated to empowering businesses to unlock their maximum potential through the implementation of innovative data and AI strategies. Our core mission is to assist businesses in leveraging their collected data to achieve cost savings and revenue growth through informed decision-making. By combining expert analysis with state-of-the-art technologies, we enable our clients to gain comprehensive insights into their data, identify crucial trends and patterns, and make strategic decisions that propel their growth and ensure long-term success."
              )}{" "}
            </Typography>
          </Col>
          <Col
            md={6}
            style={{
              padding: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <img
              src={mission}
              width={"100%"}
              style={{
                borderRadius: "30px",
                boxShadow: "0px 0px 10px 5px white",
              }}
            />
          </Col>
        </Row>
      </Row>
    </div>
  );
}

export default OurMission;
