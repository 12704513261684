import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchProducts = createAsyncThunk("products/fetch", async () => {
  const response = await axios.get("/api/products/");
  return response.data;
});

const productSlice = createSlice({
  name: "products",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        // const multipliedData = Array.from(
        //   { length: 5 },
        //   () => action.payload
        // ).flat();
        // const shuffledDataTemp = multipliedData.sort(() => Math.random() - 0.5);
        const sortedArray = action.payload.slice().sort((a, b) => {
          if (a.name === "ArtiDG") {
            return -1;
          } else if (b.name === "ArtiDG") {
            return 1;
          } else {
            return 0;
          }
        });
        state.data = sortedArray;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default productSlice.reducer;
