import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const submitApplication = createAsyncThunk(
  "application/submit",

  async ({ jobId, formData }) => {
    const csrftoken = Cookies.get("csrftoken");

    const axiosInstance = axios.create({
      headers: {
        "X-CSRFToken": csrftoken,
      },
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    await new Promise((resolve) => setTimeout(resolve, 2000));
    try {
      const response = await axiosInstance.post(
        "/api/application/",
        formData,
        config
      );
      const responseData = {
        jobId,
        data: response.data,
        status: response.status,
      };
      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(error.response.data.error);
      } else {
        throw new Error("Failed to submit application");
      }
    }
  }
);

const applicationSlice = createSlice({
  name: "application",
  initialState: {
    isLoading: false,
    submittedPositions: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitApplication.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(submitApplication.fulfilled, (state, action) => {
        state.isLoading = false;
        const { jobId } = action.payload;
        if (!state.submittedPositions.includes(jobId)) {
          state.submittedPositions.push(jobId);
        }
      })
      .addCase(submitApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default applicationSlice.reducer;
