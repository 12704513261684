import { Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleJob } from "../../../feature/job/jobSlice";
import { submitApplication } from "../../../feature/application/applicationSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader";

function Job() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMediumScreen = useMediaQuery("(max-width:1300px)");
  const [candidate, setCandidate] = useState({
    job: id,
    name: "",
    lastName: "",
    email: "",
    phone: "",
    cv: "",
    additionalInfo: "",
    startDateAv: "",
    sourceOfFinding: "",
    moreInfoLink: "",
  });
  const dispatch = useDispatch();
  const { singleJob: job, loading } = useSelector((state) => state.jobs);

  function updateCandidate(prop, value) {
    const newCandidate = { ...candidate };
    newCandidate[prop] = value;
    setCandidate(newCandidate);
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const { isLoading, submittedPositions, error } = useSelector(
    (state) => state.application
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    for (const key in candidate) {
      formData.append(key, candidate[key]);
    }
    dispatch(submitApplication({ jobId: id, formData: formData }));
  };

  useEffect(() => {
    dispatch(fetchSingleJob(id));
  }, [dispatch, id]);

  return (
    <Row
      style={{
        margin: "0px",
        padding: "0px",
        width: "100%",
        backgroundColor: "#edeef1",
      }}>
      {loading && <Loader loading={loading} />}
      <Row>
        <Col md={{ span: 6, offset: 3 }} style={{ padding: "2rem" }}>
          <Typography
            color="#000"
            variant="h3"
            padding={"3rem 0rem 2rem 0rem"}
            fontWeight={"bold"}>
            {t(job.name)}
          </Typography>
          <Typography color="#000" variant="h5" paddingBottom={"2rem"}>
            {t(job.location)}
          </Typography>
          <Typography
            color="#000"
            variant="h5"
            paddingBottom={"2rem"}
            fontWeight={"bold"}>
            {t("Description")}
          </Typography>
          <Typography color="#000" variant="h5" paddingBottom={"2rem"}>
            {job.bigDesc?.split("\r\n").map((paragraph, index) => (
              <React.Fragment key={index}>
                {t(paragraph)}
                <br />
              </React.Fragment>
            ))}
          </Typography>
          <Typography
            color="#000"
            variant="h5"
            paddingBottom={"2rem"}
            fontWeight={"bold"}>
            {t("About the Role")}
          </Typography>
          <Typography color="#000" variant="h5" paddingBottom={"2rem"}>
            {job.aboutTheRole?.split("\r\n").map((paragraph, index) => (
              <React.Fragment key={index}>
                {t(paragraph)}
                <br />
              </React.Fragment>
            ))}
          </Typography>
          <Typography
            color="#000"
            variant="h5"
            paddingBottom={"2rem"}
            fontWeight={"bold"}>
            {t("Requirements")}
          </Typography>
          <Typography color="#000" variant="h5" paddingBottom={"2rem"}>
            {job.requirements?.split("\r\n").map((paragraph, index) => (
              <React.Fragment key={index}>
                {t(paragraph)}
                <br />
              </React.Fragment>
            ))}
          </Typography>
          <Typography
            color="#000"
            variant="h5"
            paddingBottom={"2rem"}
            fontWeight={"bold"}>
            {t("Responsibilities")}
          </Typography>
          <Typography color="#000" variant="h5" paddingBottom={"2rem"}>
            {job.responsibilities?.split("\r\n").map((paragraph, index) => (
              <React.Fragment key={index}>
                {t(paragraph)}
                <br />
              </React.Fragment>
            ))}
          </Typography>
          <Typography
            color="#000"
            variant="h5"
            paddingBottom={"2rem"}
            fontWeight={"bold"}>
            {t("What Artificient offers?")}
          </Typography>
          <Typography color="#000" variant="h5" paddingBottom={"2rem"}>
            {job.wao?.split("\r\n").map((paragraph, index) => (
              <React.Fragment key={index}>
                {t(paragraph)}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </Col>
      </Row>
      <Row
        style={{ borderTop: "2px solid gray", padding: "6rem 2rem 3rem 2rem" }}>
        <Col
          md={{ span: 6, offset: 3 }}
          style={{ display: "flex", flexDirection: "column" }}>
          {!submittedPositions.includes(id.toString()) ? (
            <div>
              <Typography color="#000" variant="h3" paddingBottom={"2rem"}>
                {t("Apply for this Job")}
              </Typography>
              <Typography color="black" align="right">
                <span style={{ color: "red" }}>*</span> {t("Required")}
              </Typography>
              <Form
                style={{
                  borderBottom: "2px solid #d8d9dc",
                  paddingBottom: "2rem",
                }}>
                <Form.Group className="mb-4">
                  <Form.Label>
                    {t("First Name")} <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    value={candidate.name}
                    type="text"
                    placeholder={t("Enter your first name")}
                    required
                    onChange={(e) => updateCandidate("name", e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>
                    {t("Last Name")} <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    value={candidate.lastName}
                    type="text"
                    placeholder={t("Enter your last name")}
                    required
                    onChange={(e) =>
                      updateCandidate("lastName", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>
                    {t("Email Address")} <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    value={candidate.email}
                    style={{
                      border: error !== null ? "2px solid red" : "",
                    }}
                    type="text"
                    placeholder={t("Enter your email address")}
                    required
                    onChange={(e) => updateCandidate("email", e.target.value)}
                  />

                  {/* <Form.Text className="text-danger">
                Please enter a valid email address
              </Form.Text> */}
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>
                    {t("Phone")} <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("Enter your phone ")}
                    maxLength={15}
                    required
                    value={candidate.phone}
                    onChange={(e) => updateCandidate("phone", e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>
                    {t("Upload your CV/Resume")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="file"
                    accept=".doc,.docx,.pdf,.txt,.rtf"
                    onChange={(e) => updateCandidate("cv", e.target.files[0])}
                  />
                  <Form.Text className="text-muted">
                    ({t("File types")}: pdf, doc, docx, txt, rtf)
                  </Form.Text>
                </Form.Group>
              </Form>

              <Form
                style={{
                  borderBottom: "2px solid #d8d9dc",
                  padding: "3rem 0rem 2rem 0rem",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <Form.Group className="mb-4">
                  <Form.Label>{t("Additional Information")}</Form.Label>
                  <Form.Control
                    value={candidate.additionalInfo}
                    as="textarea"
                    rows={4}
                    onChange={(e) =>
                      updateCandidate("additionalInfo", e.target.value)
                    }
                  />
                  <Form.Text className="text-muted">
                    {t(
                      "Please share anything else you want us to know, such as your motivation to apply or additional context for your application."
                    )}
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>
                    {t(`When can you start a new role?`)}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    value={candidate.startDateAv}
                    type="date"
                    required
                    onChange={(e) =>
                      updateCandidate("startDateAv", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    {t("How did you hear about Artificient?")}
                  </Form.Label>
                  <Form.Control
                    value={candidate.sourceOfFinding}
                    type="text"
                    onChange={(e) =>
                      updateCandidate("sourceOfFinding", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>
                    {t("Where can we learn more about you?")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={candidate.moreInfoLink}
                    onChange={(e) =>
                      updateCandidate("moreInfoLink", e.target.value)
                    }
                  />
                  <Form.Text className="text-muted">
                    {t(
                      "Such as a LinkedIn profile, personal website, portfolio page."
                    )}
                  </Form.Text>
                </Form.Group>
                {error && (
                  <Typography
                    color="#000"
                    variant="h5"
                    fontWeight={"bold"}
                    paddingBottom={"2rem"}
                    align="center">
                    {t(error)}
                  </Typography>
                )}

                {isLoading ? (
                  <div className="loader-job-application" />
                ) : (
                  <Button
                    variant="outline-dark"
                    style={{ marginTop: "1rem", alignSelf: "flex-end" }}
                    type="submit"
                    onClick={handleSubmit}
                    disabled={
                      candidate.firstName === "" ||
                      candidate.lastName === "" ||
                      candidate.email === "" ||
                      candidate.phone === "" ||
                      candidate.cv === "" ||
                      candidate.cv === null ||
                      candidate.cv === undefined ||
                      !isValidEmail(candidate.email) ||
                      candidate.startDateAv === "" ||
                      isLoading
                    }>
                    {t("SUBMIT APPLICATION")}
                  </Button>
                )}
              </Form>
            </div>
          ) : (
            <div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col md={2}>
                  <CheckCircleOutlineIcon
                    className="checkmark-circle"
                    style={{
                      fontSize: 120,

                      color: "black",
                      padding: "0px",
                    }}
                  />
                </Col>
                <Col>
                  <Typography
                    color="#000"
                    variant="h4"
                    fontSize={isMediumScreen ? 30 : 50}
                    fontWeight={"bold"}
                    paddingBottom={"2rem"}
                    align="center">
                    {t("Your application has been submitted!")}
                  </Typography>
                </Col>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}>
                <Link to="/about/careers" style={{ color: "#fc2b2d" }}>
                  {t("Redirect Back To Career Page")}...{" "}
                  <ArrowBackIcon
                    style={{
                      fontSize: 40,
                      color: "black",
                      padding: "0px",
                    }}
                  />
                </Link>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Row>
  );
}

export default Job;
