import { Input, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { submitNewsletter } from "../../feature/newsletter/newsletterSlice";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

function NewsletterSignUp() {
  const [email, setEmail] = useState("");
  const [cookies, setCookie] = useCookies(["newsletterMember"]);
  const { t } = useTranslation();
  const [isValidEmail, setIsValidEmail] = useState(false);

  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const isMidScreen = useMediaQuery("(max-width:950px)");
  const dispatch = useDispatch();
  const { isLoading, isSubmitted, error } = useSelector(
    (state) => state.newsletter
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && isValidEmail && email !== "") {
      handleSubmit(event);
    } else if (event.key === "Enter" && email === "") {
      event.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      email: email,
    };
    dispatch(submitNewsletter(formData));
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value.toLowerCase())
    );
  };
  useEffect(() => {
    if (isSubmitted === true) {
      setTimeout(() => {
        setCookie("newsletterMember", true, { path: "/" });
      }, 2000);
    }
  }, [isSubmitted]);

  return (
    <div style={{ padding: "0rem", margin: "0rem", width: "100%" }}>
      <Row className="generic-row-main">
        <Typography
          variant="h2"
          color="black"
          style={{
            margin: "1rem 0rem 1rem 0rem",
            fontSize: isSmallScreen ? "35px" : "47px",
          }}>
          {t("Sign up to our newsletter")}
        </Typography>
        <Typography
          variant="subtitle1"
          color="black"
          fontSize={18}
          style={{ marginBottom: "1rem" }}>
          {t(
            "Join the cool kids' club of data governance, data science, and AI enthusiasts. We promise not to spam you with irrelevant data...or do we?"
          )}
        </Typography>
        {cookies.newsletterMember === undefined || !cookies.newsletterMember ? (
          <div style={isSmallScreen ? {} : { display: "flex" }}>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="formBasicEmail"
                style={{ color: "black" }}>
                <Form.Label
                  style={{
                    color: "black",
                    fontSize: "16px",
                    marginLeft: "3px",
                  }}>
                  Email address
                </Form.Label>
                <Form.Control
                  className="w-100"
                  type="email"
                  value={email}
                  placeholder={t("Join our inner circle. Add your email.")}
                  onKeyDown={handleKeyPress}
                  onChange={handleEmailChange}
                  isInvalid={!isValidEmail && email.length > 0}
                />
              </Form.Group>
              <Typography
                variant="subtitle2"
                color={"black"}
                style={{ margin: "2rem 2rem 2rem 0rem", marginLeft: "2.5px" }}>
                {t("We'll never share your email with anyone else.")}
              </Typography>
              {error !== null && (
                <Typography
                  variant="overline"
                  color="black"
                  border="2px solid black"
                  fontWeight={"bold"}
                  fontSize={18}
                  padding="0.5rem"
                  marginBottom={"2rem"}>
                  {t(error)}!
                </Typography>
              )}
            </Form>

            <div
              style={
                isSmallScreen
                  ? {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : {}
              }>
              {isSubmitted ? (
                <CheckCircleOutlineIcon
                  className="checkmark-circle"
                  style={{
                    fontSize: 70,
                    color: "black",
                    margin: "1rem 1rem 1rem 1rem",
                    padding: "0px",
                  }}
                />
              ) : (
                <Button
                  variant="outline-dark"
                  type="submit"
                  disabled={isLoading || !isValidEmail}
                  style={{ color: "black", margin: "2rem 1rem 2rem 1rem" }}
                  onClick={handleSubmit}>
                  {isLoading ? (
                    <span className="spinner-border spinner-border-md"></span>
                  ) : (
                    t("Submit")
                  )}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div>
            <Typography variant="h6" color="black" marginBottom={"2rem"}>
              {t("You have already subscribed")}
            </Typography>
          </div>
        )}
      </Row>
    </div>
  );
}

export default NewsletterSignUp;
