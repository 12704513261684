import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { Typography, useMediaQuery } from "@mui/material";
import MouseIcon from "@mui/icons-material/Mouse";
import { Col, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

function Manual({ show }) {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-15px"
  };

  const itemStyle = {
    flex: "0 0 auto",
    textAlign: "center",
    padding: "1rem 1rem",
  };
  return (
    <div>
      <Typography color="black" variant="h1" fontSize={24} align="center">
        The Manual
      </Typography>

      <div style={containerStyle}>
        <div style={itemStyle}>
          <Typography color="black" variant="h2" fontSize={20} align="center">
            Free Your Cursor
          </Typography>
          <div className="arrow-key-container">
            <div className="arrow-key esc" data-key="27"></div>
          </div>
        </div>
        <div style={itemStyle}>
          <Typography color="black" variant="h6" align="center">
            Move Player
          </Typography>
          <div className="arrow-key-container">
            <div className="arrow-key up" data-key="38"></div>
            <br />
            <div className="arrow-key left" data-key="37"></div>
            <div className="arrow-key down" data-key="40"></div>
            <div className="arrow-key right" data-key="39"></div>
          </div>
        </div>
        <div style={itemStyle}>
          <Typography color="black" variant="h6" align="center">
            Move Camera
          </Typography>
          <MouseIcon
            style={{
              fontSize: 40,
              color: "black",
              margin: "0",
              padding: "0px",
            }}
          />
        </div>
      </div>

      <div style={containerStyle}>
        <div style={itemStyle}>
          <Typography
            color="black"
            variant="h6"
            align="center"
            padding={"0rem 2rem"}>
            Run
          </Typography>
          <div className="arrow-key-container">
            <div className="arrow-key shift" data-key="16"></div>
          </div>
        </div>
        <div style={itemStyle}>
          <Typography
            color="black"
            variant="h6"
            align="center"
            padding={"0rem 2rem"}>
            Jump
          </Typography>
          <div className="arrow-key-container">
            <div className="arrow-key space" data-key="32"></div>
          </div>
        </div>

        <div style={itemStyle}>
          <Typography
            color="black"
            variant="h6"
            align="center"
            padding={"0rem 2rem"}>
            Crouch
          </Typography>
          <div className="arrow-key-container">
            <div className="arrow-key control" data-key="17"></div>
          </div>
        </div>
      </div>
      {!show && (
        <div style={containerStyle}>
          <Typography
            color="black"
            variant="h5"
            fontWeight={"bold"}
            align="center"
            margin={"2rem 0rem 0rem 0rem"}
            padding={"0rem 2rem"}>
            *If the game isn't responding, press 'Esc' twice to reset your game
            focus.
          </Typography>
        </div>
      )}
    </div>
  );
}

function Modal({ isOpen, onClose }) {
  return (
    <>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            margin: "auto",
            padding: "1rem",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div
            style={{
              width: "fit-content",
              height: "auto",
              background: "white",
              padding: "2rem",
              borderRadius: "4px",
              position: "relative",
            }}>
            <CloseIcon className="close-icon" onClick={onClose} />
            <Manual show={false} />
          </div>
        </div>
      )}
    </>
  );
}

function Showcase() {
  const isSmallScreen = useMediaQuery("(max-width:960px)");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { t } = useTranslation();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-ZNBTFWN36W");
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Virtual Offices",
    });
    console.log("Location: ", location);
  });

  return (
    <div>
      {!isSmallScreen ? (
        <Row
          style={{
            width: "100%",
            padding: "0",
            margin: "0",
            minWidth: "100%",
          }}>
          {!isModalOpen && (
            <div>
              <Typography
                color="black"
                variant="h5"
                fontWeight={"bold"}
                align="center"
                margin={"2rem 0rem 1rem"}
                padding={"0rem 2rem"}>
                *If the game isn't responding, press 'Esc' twice to reset your
                game focus.
              </Typography>
            </div>
          )}

          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div>
              <Iframe
                // url="https://v6p9d9t4.ssl.hwcdn.net/html/8196193/index.html"
                url = "https://html-classic.itch.zone/html/8196193/index.html"
                width="960px"
                height="700px"
                allowFullScreen="true"
              />
            </div>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div>
              <Modal isOpen={isModalOpen} onClose={closeModal} />
            </div>
            {!isModalOpen && <Manual show={true} />}
          </Col>
        </Row>
      ) : (
        <>
          <Typography
            color="black"
            variant="h5"
            align="center"
            padding={isSmallScreen ? "2rem 0rem" : "2rem 4rem"}
            margin={isSmallScreen ? "2rem 2rem" : "1rem 4rem"}>
            {t(
              "To explore our Virtual Offices please access our website from a computer."
            )}
          </Typography>
          <Typography
            color="black"
            variant="h5"
            align="center"
            padding={isSmallScreen ? "2rem 0rem" : "2rem 4rem"}
            margin={isSmallScreen ? "2rem 2rem" : "1rem 4rem"}>
            {t(
              "If you are already from a computer please maximize your window."
            )}
          </Typography>
        </>
      )}
    </div>
  );
}

export default Showcase;
