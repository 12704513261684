import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Flag from "react-world-flags";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { Form, Dropdown, DropdownButton } from "react-bootstrap";

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const preferredLanguage = localStorage.getItem("preferredLanguage");
    if (preferredLanguage) {
      setSelectedLanguage(preferredLanguage);
      i18n.changeLanguage(preferredLanguage);
    } else if (preferredLanguage === null || preferredLanguage === undefined) {
      localStorage.setItem("preferredLanguage", "en");
      setSelectedLanguage("en");
      i18n.changeLanguage("en");
    }
  }, []);

  const changeLanguage = (lng) => {
    setSelectedLanguage(lng);
    localStorage.setItem("preferredLanguage", lng);
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <div>
        <Dropdown
          onSelect={(value) => changeLanguage(value)}
          className="language-selector"
          style={{
            margin: "0px 0px 16px 0px",
            padding: "1rem 0rem 0rem 0rem",
          }}>
          <Dropdown.Toggle
            className="language-selector-toggle"
            style={{
              border: "none",
              backgroundColor: "transparent",
              margin: "0px 0px 0px 0px",
              padding: "3px 2rem 5px 0.5rem",
              boxShadow: "none",
            }}
            id="dropdown-basic"
            variant="light">
            <Flag
              code={selectedLanguage === "en" ? "GB" : selectedLanguage}
              style={{
                margin: "0px 5px 0px 0px",
                padding: "0px",
                maxHeight: "10vh",
              }}
            />
            {selectedLanguage}
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="language-selector-menu"
            aria-expanded={open}
            style={{
              padding: "0.5rem 0.1rem 0.5rem 0rem",
              justifyContent: "center",
            }}>
            <Dropdown.Item
              eventKey="en"
              style={{ minWidth: "105px", padding: "0.5rem" }}>
              <Flag
                code="GB"
                style={{
                  marginRight: "10px",
                  width: "35%",
                }}
              />
              English
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="gr"
              style={{ maxWidth: "105px", padding: "0.5rem" }}>
              <Flag code="GR" style={{ marginRight: "10px", width: "35%" }} />
              Greek
            </Dropdown.Item>
            {/* <Dropdown.Item
              eventKey="ru"
              style={{ maxWidth: "105px", padding: "0.5rem" }}>
              <Flag code="RU" style={{ marginRight: "10px", width: "35%" }} />
              Russia
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default LanguageSwitcher;
