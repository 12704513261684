import { nanoid } from "nanoid";
import nora from "./assets/images/artinora.webp";
import skillz from "./assets/images/artiskillz.webp";
import dg from "./assets/images/artidg.webp";

const headerData = [
  {
    _id: 1,
    name: "about",
    items: [
      {
        id: nanoid(),
        title: "Company",
        image: undefined,
        description: "Providing comprehensive data management solutions",
        path: "/about/company",
      },
      {
        id: nanoid(),
        title: "Team",
        image: undefined,
        description: "Innovative thinkers in data solutions",
        path: "/about/team",
      },
      {
        id: nanoid(),
        title: "Careers",
        image: undefined,
        description: "Seeking talented data professionals",
        path: "/about/careers",
      },
    ],
  },
  {
    _id: 2,
    name: "products",
    items: [
      {
        id: nanoid(),
        title: "ArtiDG",
        image: dg,
        description: "Simplifying Data Governance for businesses/organizations",
        path: "/products/ArtiDG",
      },
      {
        id: nanoid(),
        title: "ArtiSkillz",
        image: skillz,
        description: "AI-driven tool for talent selection",
        path: "/products/ArtiSkillz",
      },
      {
        id: nanoid(),
        title: "ArtiNora",
        image: nora,
        description: "Next-gen ERP with AI technology",
        path: "coming soon",
      },
    ],
  },
  {
    _id: 3,
    name: "services",
    items: [
      {
        id: nanoid(),
        title: "DataSync",
        image: undefined,
        description: "Unleashing the power of synchronized data",
        path: "/services/dataSync",
      },
      {
        id: nanoid(),
        title: "DataInsight",
        image: undefined,
        description: "Unveiling the hidden potential of data",
        path: "/services/dataInsight",
      },
      {
        id: nanoid(),
        title: "DataMind",
        image: undefined,
        description: "Transforming data into actionable wisdom",
        path: "/services/dataMind",
      },
    ],
  },
];

export default headerData;
