import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { setWhiteLogo } from "../../feature/logo/logoSlice";
import { useDispatch, useSelector } from "react-redux";

const Search = ({ isOpen, onClose, setOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logoVariant } = useSelector((state) => state.logo);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  if (!isOpen) {
    return null;
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    navigate(`/results/${searchQuery}`);
    dispatch(setWhiteLogo());
    handleClose();
    setOpen(false);
  };

  const handleClose = () => {
    setSearchQuery("");
    onClose();
  };

  return (
    <div className="search-overlay">
      <div className="search-box">
        <div className="input-container">
          <input
            style={{ color: "black" }}
            type="text"
            placeholder={t("Search the entire site")}
            value={searchQuery}
            onKeyDown={handleKeyPress}
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          {searchQuery && (
            <div
              className="backspace-icon-container"
              onClick={() => setSearchQuery("")}>
              <BackspaceIcon className="clear-icon" />
            </div>
          )}
        </div>

        <button className="search-button" onClick={handleSearch}>
          {t("PRESS ENTER TO SEARCH")}
        </button>
      </div>

      <CloseIcon className="close-icon" onClick={handleClose} />
    </div>
  );
};

export default Search;
