import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/onlyLogoArtificient.webp";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(3000);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      navigate("/");
    }
  }, [seconds]);
  return (
    <div className="not-found">
      <img src={logo} className="spin-image" />
      <h1>404 - {t("Page not found")}</h1>
      <p>{t("The page you requested could not be found.")}</p>
      <Link to="/">
        {t("Redirecting To Home Page in")} {seconds / 1000}
      </Link>
    </div>
  );
};

export default PageNotFound;
