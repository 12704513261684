import React, { useEffect, useState } from "react";

import { Typography, useMediaQuery } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeamMembers } from "../../feature/teamMember/teamMemberSlice";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

function AboutTeam() {
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: team, loading } = useSelector((state) => state.teamMembers);

  useEffect(() => {
    dispatch(fetchTeamMembers());
  }, [dispatch]);
  return (
    <div style={{ padding: "0rem", margin: "0rem", width: "100%" }}>
      {loading && <Loader loading={loading} />}
      <Row className="generic-row-main">
        <div
          style={{
            padding: isSmallScreen
              ? "5rem 2rem 3rem 2rem"
              : "5rem 4rem 3rem 4rem",
          }}>
          <Typography
            align="center"
            color="#212165"
            variant={isSmallScreen ? "h2" : "h1"}
            fontWeight={"bold"}
            fontSize={isSmallScreen ? "30px" : "35px"}>
            {t("Our Talented Team:")}
          </Typography>
          <Typography
            align="center"
            color="#212165"
            variant={isSmallScreen ? "h3" : "h2"}
            fontWeight={"bold"}
            fontSize={isSmallScreen ? "30px" : "35px"}>
            {t("Meet the Individuals Driving Our Company's Success")}
          </Typography>
        </div>

        <div className="team-member">
          {team.map((member, index) => {
            const isLastItem = index === team.length - 1;
            const isOdd = index % 2 === 1 ? true : false;
            return (
              <Row
                key={member._id}
                style={{
                  margin: "0",
                  padding: "0",
                  width: "100%",
                  borderBottom: isLastItem ? "none" : "4px solid #707070",
                  ...(isOdd
                    ? { display: "flex", flexDirection: "row-reverse" }
                    : {}),
                }}>
                <Col
                  md={4}
                  style={{
                    padding: isSmallScreen ? "1rem 2rem 2rem 2rem" : "4rem",
                  }}>
                  <img
                    src={member.image}
                    width={"100%"}
                    style={{ borderRadius: "25px" }}
                  />
                  <Typography
                    align="center"
                    color="#212165"
                    variant="h5"
                    fontWeight={"bold"}
                    paddingTop={"2rem"}>
                    {t(member.name)}
                  </Typography>
                  <Typography
                    align="center"
                    color="#212165"
                    variant="h5"
                    fontWeight={"bold"}
                    paddingTop={"1rem"}>
                    {t(member.jobRole)}
                  </Typography>
                </Col>
                <Col
                  md={8}
                  style={{
                    padding: isSmallScreen ? "1rem 2rem 4rem 2rem" : "4rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Card
                    style={{
                      border: "3px solid #292ebe",
                      padding: isSmallScreen ? "1rem" : "3rem",
                    }}>
                    <Typography
                      align="center"
                      color="#212165"
                      variant={isSmallScreen ? "h6" : "h5"}>
                      {t(member.description)}
                    </Typography>
                  </Card>
                </Col>
              </Row>
            );
          })}
        </div>
      </Row>
    </div>
  );
}

export default AboutTeam;
