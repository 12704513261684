import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import videoURLweb from "../../assets/videos/ArtificientSplashScreen.webm";
import axios from "axios";

export const fetchSplashScreenIOSVideo = createAsyncThunk(
  "splashScreenIOSVideo/fetch",
  async (videoName) => {
    try {
      const iOSVideoResponse = await axios.get(`/api/video/${videoName}`);
      const iOSVideoData = iOSVideoResponse.data;
      return { iOSVideoData };
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  showVideo: false,
  videoURL: videoURLweb,
  isPlaying: false,
};

const splashScreenSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    playVideo: (state) => {
      state.isPlaying = true;
    },
    pauseVideo: (state) => {
      state.isPlaying = false;
    },
    hideVideo: (state) => {
      state.showVideo = false;
    },
    appearVideo: (state) => {
      state.showVideo = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSplashScreenIOSVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSplashScreenIOSVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.videoURL = action.payload.iOSVideoData.video;
      })
      .addCase(fetchSplashScreenIOSVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { playVideo, pauseVideo, hideVideo, appearVideo } =
  splashScreenSlice.actions;

export default splashScreenSlice.reducer;