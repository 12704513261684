import { createSlice } from "@reduxjs/toolkit";
import whiteLogo from "../../assets/images/whiteLogoArtificient.webp";
import colorLogo from "../../assets/images/colorLogoArtificient.webp";

const initialState = {
  logoVariant: whiteLogo,
};

const logoSlice = createSlice({
  name: "logo",
  initialState,
  reducers: {
    setColorLogo: (state) => {
      state.logoVariant = colorLogo;
    },
    setWhiteLogo: (state) => {
      state.logoVariant = whiteLogo;
    },
  },
});

export const { setColorLogo, setWhiteLogo } = logoSlice.actions;

export default logoSlice.reducer;
