import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const submitNewsletter = createAsyncThunk(
  "newsletter/submit",

  async (formData) => {
    const csrftoken = Cookies.get("csrftoken");

    const axiosInstance = axios.create({
      headers: {
        "X-CSRFToken": csrftoken,
      },
    });
    await new Promise((resolve) => setTimeout(resolve, 1500));
    try {
      const response = await axiosInstance.post("/api/subscribe/", formData);
      const responseData = {
        data: response.data,
        status: response.status,
      };

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(error.response.data.error);
      } else {
        throw new Error("Failed to submit newsletter");
      }
    }
  }
);

const newsletterSlice = createSlice({
  name: "newsletter",
  initialState: {
    isLoading: false,
    isSubmitted: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitNewsletter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(submitNewsletter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSubmitted = true;
      })
      .addCase(submitNewsletter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default newsletterSlice.reducer;
