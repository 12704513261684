import { Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Col, Nav, NavDropdown, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import {
  openDropDown,
  closeDropDown,
  fetchHeaderDropdowns,
} from "../../feature/dropDown/dropDownSlice";
import { useTranslation, Trans } from "react-i18next";
import { setWhiteLogo } from "../../feature/logo/logoSlice";
import { nanoid } from "nanoid";

function HeaderDropdown({ setOpen }) {
  const dispatch = useDispatch();
  const { activeDropdown, headerDropdowns, loading, error } = useSelector(
    (state) => state.dropdowns
  );

  const { logoVariant } = useSelector((state) => state.logo);
  const { t } = useTranslation();

  // useEffect(() => {
  //   dispatch(fetchHeaderDropdowns());
  // }, [dispatch]);

  const isToggleOn = useMediaQuery("(max-width:1399px)");

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div>
      {!isToggleOn ? (
        <div className="sections-with-dropdown" key={nanoid()}>
          {headerDropdowns !== undefined &&
            headerDropdowns.length !== 0 &&
            Object.entries(headerDropdowns).map(([key, value]) => {
              let nestedItems = [];
              let start = 0;
              while (start < value.items.length) {
                let remaining = value.items.length - start;
                let itemsInRow = remaining;
                if (remaining > 4) {
                  itemsInRow = remaining % 3 === 2 ? 4 : 3;
                }
                nestedItems.push(value.items.slice(start, start + itemsInRow));
                start += itemsInRow;
              }
              nestedItems = nestedItems.reverse();

              return (
                <div
                  key={nanoid()}
                  className="dropdown-stay-active"
                  onMouseLeave={() => {
                    dispatch(closeDropDown());
                  }}>
                  <div
                    className="dropdown-activator"
                    key={nanoid()}
                    onMouseOver={() => {
                      dispatch(openDropDown(value.name));
                    }}>
                    <LinkContainer
                      key={nanoid()}
                      to={`/${value.name}`}
                      className="nav-link"
                      onClick={(e) => e.preventDefault()}>
                      <Nav.Link>
                        {t(capitalizeFirstLetter(value.name))}
                      </Nav.Link>
                    </LinkContainer>
                    {activeDropdown === `${value.name}` ? (
                      <div className="hidden-submenu-dropdown">
                        <div className="submenu-dropdown" key={key}>
                          {nestedItems.map((rowItems, rowIndex) => (
                            <Row
                              key={nanoid()}
                              style={{
                                margin: "0px",
                                padding: "0px",
                                width: "100%",
                                ...(activeDropdown === "products"
                                  ? {
                                      minHeight: "280px",
                                    }
                                  : {
                                      minHeight: "180px",
                                    }),
                              }}>
                              {rowItems.map((item, index) => {
                                const isLastItem =
                                  index === rowItems.length - 1;
                                const columnWidth = 12 / rowItems.length;

                                return (
                                  <Col
                                    key={index}
                                    md={columnWidth}
                                    className={
                                      isLastItem
                                        ? "last-submenu-container"
                                        : "submenu-container"
                                    }
                                    style={{
                                      padding: "50px 120px !important",
                                    }}>
                                    <Link
                                      key={nanoid()}
                                      to={
                                        item.path !== undefined &&
                                        item.path !== "coming soon"
                                          ? `${item.path}`
                                          : ""
                                      }
                                      onClick={
                                        item.path === undefined ||
                                        item.path === "coming soon"
                                          ? (e) => e.preventDefault()
                                          : () => {
                                              dispatch(closeDropDown());
                                              dispatch(setWhiteLogo());
                                            }
                                      }>
                                      {item.image !== undefined &&
                                      item.image !== null ? (
                                        <Row>
                                          <img
                                            className="submenu-img-top"
                                            style={{ height: "12vh" }}
                                            src={`${item.image}`}
                                            alt={`${item.title} Logo`}
                                          />
                                        </Row>
                                      ) : (
                                        <></>
                                      )}
                                      <Row>
                                        {activeDropdown !== "products" && (
                                          <Typography
                                            variant="h5"
                                            color="#767676"
                                            fontWeight={"bold"}
                                            textAlign={"center"}>
                                            {t(item.title)}
                                          </Typography>
                                        )}
                                      </Row>
                                      <Row>
                                        <Typography
                                          variant="body1"
                                          color="#767676"
                                          textAlign={"center"}>
                                          {t(item.description)}
                                        </Typography>
                                      </Row>
                                      {activeDropdown === "products" && (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignmentItems: "center",
                                            height: "32px",
                                          }}>
                                          {item.path === "coming soon" ? (
                                            <Typography
                                              variant="overline"
                                              maxWidth={"100%"}
                                              alignContent={"center"}
                                              style={{ color: "#ca6969" }}>
                                              {t(item.path)}...
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      )}
                                    </Link>
                                  </Col>
                                );
                              })}
                            </Row>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div className="dropdown-section-small-screen">
          {Object.entries(headerDropdowns).map(([key, value]) => {
            return (
              <div
                className="alternative-dropdown"
                key={nanoid()}
                style={{
                  display: "flex",
                }}>
                <NavDropdown
                  key={nanoid()}
                  className="drop-click"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                  }}
                  title={`${t(capitalizeFirstLetter(value.name))}`}
                  id="basic-nav-dropdown">
                  {value.items.map((data) => (
                    <LinkContainer
                      key={nanoid()}
                      to={`${data?.path}`}
                      style={{ display: "inline-block" }}
                      className="nav-link-2"
                      onClick={
                        data.path === "coming soon"
                          ? (e) => e.preventDefault()
                          : () => {
                              setOpen(false);
                              dispatch(setWhiteLogo());
                            }
                      }>
                      <NavDropdown.Item
                        key={nanoid()}
                        style={{ padding: "1rem 2rem 1rem 2rem !important" }}>
                        {t(data.title)}
                      </NavDropdown.Item>
                    </LinkContainer>
                  ))}
                </NavDropdown>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default HeaderDropdown;
