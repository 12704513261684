import { Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import "../../screens/HomeScreen.css";
import { Col, Row } from "react-bootstrap";
import Partner from "./Partner";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartners } from "../../feature/partner/partnerSlice";
import { useTranslation } from "react-i18next";

function Partners() {
  const { t } = useTranslation();
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollContainerRef = useRef(null);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const dispatch = useDispatch();

  const { data: partners } = useSelector((state) => state.partners);

  useEffect(() => {
    dispatch(fetchPartners());
  }, [dispatch]);

  const handleMouseDown = (e) => {
    setIsDown(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
  };

  const handleMouseUp = () => {
    setIsDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div style={{ padding: "0rem", margin: "0rem", width: "100%" }}>
      <Row className="generic-row-main">
        <div style={{ padding: isSmallScreen ? "0rem" : "2rem 0rem" }}>
          <Typography
            variant="h2"
            color={"#000000"}
            fontWeight={"bold"}
            fontSize={
              isSmallScreen ? "35px" : "47px"
            }>
            {t("Partners")}
          </Typography>
          <Typography variant="h6" color={"#000000"} fontWeight={"bold"}>
            {t("Innovating Together")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <div
            className="scrollable-container"
            ref={scrollContainerRef}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}>
            {partners !== undefined &&
            partners !== null &&
            Array.isArray(partners)
              ? partners.map((partner) => (
                  <div key={partner._id}>
                    <Partner partner={partner} />
                  </div>
                ))
              : []}
          </div>
        </div>
      </Row>
    </div>
  );
}

export default Partners;
