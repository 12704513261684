import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import headerData from "../../headerData";

export const fetchHeaderDropdowns = createAsyncThunk(
  "dropdowns/fetchHeader",
  async () => {
    const response = await axios.get("/api/headerDropdowns");
    return response.data;
  }
);

const initialState = {
  activeDropdown: "",
  headerDropdowns: headerData,
  loading: false,
  error: null,
};

const dropDownSlice = createSlice({
  name: "dropdowns",
  initialState,
  reducers: {
    openDropDown: (state, action) => {
      state.activeDropdown = action.payload;
    },
    closeDropDown: (state) => {
      state.activeDropdown = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHeaderDropdowns.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHeaderDropdowns.fulfilled, (state, action) => {
        state.loading = false;
        state.headerDropdowns = action.payload;
      })
      .addCase(fetchHeaderDropdowns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { openDropDown, closeDropDown } = dropDownSlice.actions;

export default dropDownSlice.reducer;
