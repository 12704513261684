import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import OurCommitment from "./Company/OurCommitment";
import OurMission from "./Company/OurMission";
import OurOffices from "./Company/OurOffices";

function AboutCompany(props) {
  return (
    <div>
      <OurCommitment />
      <OurMission />
      <OurOffices />
    </div>
  );
}

AboutCompany.propTypes = {};

export default AboutCompany;
