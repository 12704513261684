import { Paper, Typography } from "@mui/material";
import React from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function CookiePolicy() {
  const { t } = useTranslation();
  return (
    <div style={{ padding: "0rem", margin: "0rem", width: "100%" }}>
      <div
        style={{
          padding: "0rem",
          margin: "0rem",
          width: "100%",
          height: "auto",
          backgroundColor: "black",
        }}>
        <Typography
          align="center"
          color="white"
          variant={"h5"}
          padding={"1rem 0rem 1rem 0rem"}
          fontWeight={"800"}>
          {t("Cookie Policy")}
        </Typography>
      </div>
      <Row className="generic-row-main">
        <Paper
          elevation={12}
          style={{
            backgroundColor: "#f6f6f7",
            borderRadius: "10px",
            margin: "5% 0rem 5% 0rem",
            border: "8px double black",
          }}>
          <Typography
            color="black"
            variant="body1"
            padding={"2rem 1rem 1rem 1rem"}
            fontWeight="bold">
            {t(
              "We use cookies when you visit our site, but you can control these through your browser settings. You can find out how to manage cookies on your devices below."
            )}
          </Typography>
          <Typography
            color="black"
            variant="body1"
            padding={"1rem"}
            fontWeight="bold">
            {t("What are cookies?")}
          </Typography>
          <Typography color="black" variant="body1" padding={"1rem"}>
            {t(
              "Cookies are data files that can hold small amounts of info and are stored on your device (computer, smartphone etc) when you first visit a website."
            )}
          </Typography>
          <Typography
            color="black"
            variant="body1"
            padding={"1rem"}
            fontWeight="bold">
            {t("How do we use them?")}
          </Typography>
          <Typography color="black" variant="body1" padding={"1rem"}>
            {t("We use cookies for:")}
            <ul>
              <li>{t("essential operations, like site navigation")}</li>
              <li>{t("allowing you to remember your preferred language")}</li>
              <li>
                {t(
                  "analysing visitor numbers and behaviours, such as what pages are frequently visited"
                )}
              </li>
            </ul>
          </Typography>
          <Typography
            color="black"
            variant="body1"
            padding={"1rem"}
            fontWeight="bold">
            {t("What types of cookies do we use?")}
          </Typography>
          <Typography color="black" variant="body1" padding={"1rem"}>
            {t("There are three main types.")}

            <ol>
              <li>
                {t(
                  "Site functionality cookies – these allow you to navigate the site and use our features, such as 'Submit Application'."
                )}
              </li>
              <li>
                {t(
                  "Site analytics cookies – these cookies allow us to measure and analyse how our customers use the site, to improve both its functionality and your experience."
                )}
              </li>
              <li>
                {t(
                  "Customer preference cookies – when you're browsing on Artificient, these cookies will remember your preferences (like your language or location), so we can make your experience as seamless as possible, and more personal to you."
                )}
              </li>
            </ol>
          </Typography>
          <Typography
            color="black"
            variant="body1"
            padding={"1rem"}
            fontWeight="bold">
            {t("How to manage cookies")}
          </Typography>
          <Typography
            color="black"
            variant="body1"
            padding={"1rem 1rem 2rem 1rem"}>
            {t(
              "Most browsers allow you to manage cookies saved on your device – just head to the help section of your browser. But don’t forget, if you modify your settings to block all cookies, you may not be able to access parts of our site or our service."
            )}
          </Typography>
        </Paper>
      </Row>
    </div>
  );
}

export default CookiePolicy;
