import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import "./index.css";
import "./bootstrap.min.css";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { Provider } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ReactGA from "react-ga4";
// import { HashRouter } from "react-router-dom";

ReactGA.initialize("G-ZNBTFWN36W");

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// reportWebVitals();
