import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchJobs = createAsyncThunk("jobs/fetch", async () => {
  const response = await axios.get("/api/jobs/");
  return response.data;
});

export const fetchSingleJob = createAsyncThunk(
  "jobs/fetchSingleJob",
  async (id) => {
    const response = await axios.get(`/api/job/${id}`);
    return response.data;
  }
);

const jobSlice = createSlice({
  name: "jobs",
  initialState: {
    data: [],
    singleJob: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSingleJob.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.singleJob = [];
      })
      .addCase(fetchSingleJob.fulfilled, (state, action) => {
        state.loading = false;
        state.singleJob = action.payload;
      })
      .addCase(fetchSingleJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default jobSlice.reducer;
