import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBBtn,
} from "mdb-react-ui-kit";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <MDBFooter className="text-start" color="white" bgColor="black">
      <MDBContainer className="p-4">
        <section className="text-end mb-4">
          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="#!"
            role="button"
            rippleColor="white"
            onClick={() =>
              window.open(
                "https://www.facebook.com/people/Artificient/100075976932507/"
              )
            }>
            <MDBIcon fab icon="facebook-f" />
          </MDBBtn>

          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="#!"
            role="button"
            onClick={() => {
              window.open("https://www.linkedin.com/company/artificient");
            }}>
            <MDBIcon fab icon="linkedin-in" />
          </MDBBtn>
        </section>

        <section className="mb-4">
          <MDBRow>
            <MDBCol lg="2" md="5" className="mb-4 mb-md-0">
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/" className="text-white footer-item">
                    {t("Home")}
                  </Link>
                </li>
                <li>
                  <Link to="/news" className="text-white footer-item">
                    {t("News")}
                  </Link>
                </li>
              </ul>
            </MDBCol>

            <MDBCol lg="2" md="5" className="mb-2 mb-md-0">
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/contact-us" className="text-white footer-item">
                    {t("Contact us")}
                  </Link>
                </li>
                <li>
                  <Link to="/about/company/showcase" className="text-white footer-item">
                    {t("Virtual Offices")}
                  </Link>
                </li>
              </ul>
            </MDBCol>

            <MDBCol lg="2" md="5" className="mb-2 mb-md-0">
              <ul className="list-unstyled mb-0">
                <li>
                  <a href="https://www.artidg.com/#/" className="text-white footer-item">
                    ArtiDG
                  </a>
                </li>
                <li>
                  <Link to="/products/ArtiSkillz" className="text-white footer-item">
                    ArtiSkillz
                  </Link>
                </li>
              </ul>
            </MDBCol>

            <MDBCol lg="2" md="5" className="mb-2 mb-md-0">
              <ul className="list-unstyled mb-0">
                <li>
                  <Link
                    to="/services/dataSync"
                    className="text-white footer-item">
                    {t("DataSync")}
                  </Link>
                </li>
                <li>
                  <Link to="/services/dataInsight" className="text-white footer-item">
                    {t("DataInsight")}
                  </Link>
                </li>
                <li>
                  <Link to="/services/dataMind" className="text-white footer-item">
                    {t("DataMind")}
                  </Link>
                </li>
              </ul>
            </MDBCol>
            <MDBCol lg="3" md="2" className="mb-2 mb-md-0">
              <p>
                {t(
                  "Connect with us to learn more about our innovative solutions and how we can help you transform your data into a strategic asset"
                )}
              </p>
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "black" }}>
        <a style={{ color: "white"}}>
        © 2020 Copyright:
        </a>
        <a href="https://artificient.org/"
        style={{ color: "red" }}>
          &ensp;A.A. ARTIFICIENT LIMITED &ensp;
        </a>
        <a style={{ color: "white"}}>

        | &ensp; {t("All rights reserved")}
        </a>
      </div>
    </MDBFooter>
  );
}

Footer.propTypes = {};

export default Footer;
