import { configureStore } from "@reduxjs/toolkit";
import splashScreenReducer from "./feature/splashScreen/splashScreenSlice";
import logoReducer from "./feature/logo/logoSlice";
import dropdownReducer from "./feature/dropDown/dropDownSlice";
import newsReducer from "./feature/news/newsSlice";
import partnerReducer from "./feature/partner/partnerSlice";
import productReducer from "./feature/product/productSlice";
import jobReducer from "./feature/job/jobSlice";
import teamMemberReducer from "./feature/teamMember/teamMemberSlice";
import newsletterReducer from "./feature/newsletter/newsletterSlice";
import applicationReducer from "./feature/application/applicationSlice";
import searchReducer from "./feature/search/searchSlice";
import serviceContextReducer from "./feature/serviceContext/serviceContextSlice";
import productContextReducer from "./feature/productContext/productContextSlice";
import contactUsReducer from "./feature/contact/contactUsSlice";

export const store = configureStore({
  reducer: {
    video: splashScreenReducer,
    logo: logoReducer,
    dropdowns: dropdownReducer,
    news: newsReducer,
    partners: partnerReducer,
    products: productReducer,
    jobs: jobReducer,
    teamMembers: teamMemberReducer,
    newsletter: newsletterReducer,
    application: applicationReducer,
    search: searchReducer,
    serviceContext: serviceContextReducer,
    productContext: productContextReducer,
    contactUs: contactUsReducer,
  },
});
