import React, { useEffect, useRef, useState } from "react";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Col,
  Row,
  Dropdown,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import PropTypes from "prop-types";
import "./Header.css";
import MenuIcon from "@mui/icons-material/Menu";
import { setColorLogo, setWhiteLogo } from "../feature/logo/logoSlice";
import { useDispatch, useSelector } from "react-redux";
import { Typography, useMediaQuery } from "@mui/material";
import HeaderDropdown from "./Header/HeaderDropdown";
import { Link } from "react-router-dom";
// import headerData from "../headerData";
import Search from "./Header/Search";
import LanguageSwitcher from "./Header/LanguageSwitcher";
import { useTranslation } from "react-i18next";

function Header() {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(
    /Mobi|Android/i.test(navigator.userAgent)
  );
  const { logoVariant } = useSelector((state) => state.logo);
  const { t } = useTranslation();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (isMobile) {
      dispatch(setWhiteLogo());
    }
  }, [isMobile]);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <Navbar
      className="navbar-arti"
      variant="dark"
      expand="xxl"
      onMouseEnter={() => {
        if (!isMobile) {
          dispatch(setColorLogo());
        }
      }}
      onMouseLeave={() => {
        if (!isMobile) {
          dispatch(setWhiteLogo());
        }
      }}
      expanded={open}
      onToggle={() => setOpen(!open)}>
      <Container>
        <LinkContainer to="/" onClick={() => setOpen(false)}>
          <Navbar.Brand>
            <img
              src={logoVariant}
              style={{
                display: "block",
                height: "60px",
                width: "auto",
                paddingLeft: "2rem",
              }}
              alt="Logo"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" xxl={"true"}>
          <MenuIcon
            className="menu-toggle-icon"
            style={{
              fontSize: 40,
              color: "white",
              border: "1px solid white",
              borderRadius: "5px",
              margin: "0rem 0rem 0rem 0rem",
              padding: "0px",
            }}
          />
        </Navbar.Toggle>
        <Navbar.Collapse
          ref={dropdownRef}
          id="basic-navbar-nav"
          className="custom-collapsed-nav-bar"
          onMouseLeave={() => setOpen(false)}>
          <Nav className="main-menu-right">
            <LinkContainer
              to="/"
              className="nav-link"
              onClick={() => setOpen(false)}>
              <Nav.Link>{t("Home")}</Nav.Link>
            </LinkContainer>

            <HeaderDropdown setOpen={setOpen} />

            <LinkContainer
              to="/news"
              className="nav-link"
              onClick={() => setOpen(false)}>
              <Nav.Link>{t("News")}</Nav.Link>
            </LinkContainer>

            <div className="nav-link" onClick={() => setIsSearchOpen(true)}>
              <i className="fa-solid fa-search"></i>
            </div>
            <Search
              isOpen={isSearchOpen}
              setOpen={setOpen}
              onClose={() => setIsSearchOpen(false)}
            />

            <LinkContainer
              to="/contact-us"
              className="nav-link contact-us-btn"
              onClick={() => setOpen(false)}>
              <Nav.Link className="contact-us-btn">{t("Contact Us")}</Nav.Link>
            </LinkContainer>
            <div>
              <LanguageSwitcher />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
