import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

function VideoPlayer({ url }) {
  //THE ERROR IS COMING FROM THE PROP playsinline

  // const [playsInline, setPlaysInline] = useState(true);

  // const handlePlayerError = () => {
  //   setPlaysInline(false);
  // };

  return (
    <div>
      <ReactPlayer
        playsinline
        className="react-player-homepage"
        muted={true}
        playing={true}
        loop={true}
        url={url}
        width="100%"
        height="100%"
        controls={false}
        // onError={handlePlayerError}
      />
      {/* <div className="color-overlay" /> */}
    </div>
  );
}

export default VideoPlayer;
