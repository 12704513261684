import React from "react";
import { Box, Button, Container, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { LinkContainer } from "react-router-bootstrap";
import styled from "@emotion/styled";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Col, Nav, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const theme = createTheme();

const MyBox = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: theme.spacing(3),
  gap: theme.spacing(2),
  width: "100%",
  borderRadius: 30,
  boxShadow: theme.shadows[8],
  "& .learn-more": {
    textDecoration: "none",
    position: "relative",
    overflow: "hidden",
    "&:hover": {
      "& .button-more": {
        color: "#fc2b2d",
      },
      "& .hover-line": {
        width: "100%",
      },
    },
  },
  "& .hover-line": {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: 0,
    height: 4,
    backgroundColor: "#fc2b2d",
    transition: "width 0.3s",
  },
  "& .box-content": {
    minHeight: 135,
  },
}));

function ServicesSectionHomePage() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const boxes = [
    {
      title: "DataSync",
      content:
        "Maximize your data's potential with our DataSync services. At Artificient, we understand the critical role that data plays in today's business landscape. Our comprehensive DataSync solutions are designed to optimize the performance, accuracy, security, and compliance of your data, empowering you to achieve your business goals with confidence.",
      path: "/services/dataSync",
    },
    {
      title: "DataInsight",
      content:
        "Uncover hidden insights and make informed decisions with our DataInsight services. At Artificient, we use advanced techniques to analyze your data and deliver customized solutions. From predictive modeling to data visualization, we help you unlock your data's full potential and gain a competitive edge in your industry.",
      path: "/services/dataInsight",
    },
    {
      title: "DataMind",
      content:
        "Stay ahead of the competition with our DataMind services. At Artificient, we harness the power of cutting-edge technologies to automate and optimize your business processes. Our team of experts specializes in delivering tailored solutions, from intelligent chatbots to recommendation systems, enabling you to enhance efficiency, cut costs, and foster innovation.",
      path: "/services/dataMind",
    },
  ];

  return (
    <div
      style={{
        margin: "0px",
        padding: "0px",
        width: "100%",
      }}>
      <Row className="generic-row-main">
        <Box width="100%">
          <Box mt={4} mb={6}>
            <Typography
              variant="h2"
              align="left"
              color={"#161616"}
              fontWeight={"bold"}
              fontSize={
                isSmallScreen ? "35px" : "47px"
              }
              gutterBottom>
              {t("Services")}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              fontWeight={"bold"}
              color={"#161616"}>
              {t("Tailored Data Consulting")}
            </Typography>
          </Box>

          <Row>
            {boxes.map((box, index) => (
              <Col
                key={index}
                style={{ padding: "1rem 1rem 1rem 1rem" }}
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}>
                <MyBox style={{ height: "100%" }}>
                  <Typography
                    variant="h5"
                    fontWeight={"bold"}
                    color={"#333333"}>
                    {t(box.title)}
                  </Typography>
                  <Typography color={"#868686"} className="box-content">
                    {t(box.content)}
                  </Typography>
                  <LinkContainer to={box.path}>
                    <div className="learn-more">
                      <Typography
                        variant={"h6"}
                        className="button-more"
                        style={{ color: "#000000", fontWeight: "700" }}>
                        {t("Learn more")}{" "}
                        <ArrowCircleRightOutlinedIcon
                          style={{
                            fontSize: 40,
                            color: "black",
                            margin: "1rem 0rem 1rem 0rem",
                            padding: "0px",
                          }}
                        />
                      </Typography>
                      <div className="hover-line" />
                    </div>
                  </LinkContainer>
                </MyBox>
              </Col>
            ))}
          </Row>
        </Box>
      </Row>
    </div>
  );
}

export default ServicesSectionHomePage;
