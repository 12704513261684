import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import EastIcon from "@mui/icons-material/East";
import { LinkContainer } from "react-router-bootstrap";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../feature/news/newsSlice";
import Loader from "../components/Loader";
import AutoSizeDiv from "../components/AutoSizeDiv";
import ScaleText from "react-scale-text";
import { useTranslation } from "react-i18next";

function News() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { data, loading } = useSelector((state) => state.news);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [category, setCategory] = useState("");

  useEffect(() => {
    dispatch(fetchNews());
  }, [dispatch]);

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const date = new Date(dateString);
    const month = t(date.toLocaleString("default", { month: "long" }));
    const formattedDate = date.toLocaleDateString(undefined, options);

    return formattedDate.replace(
      date.toLocaleDateString("default", { month: "long" }),
      month
    );
  }

  let categories = Array.isArray(data) ? data.map((item) => item.category) : "";

  let uniqueCategories = [...new Set(categories)];

  return (
    <div className="all-news">
      {loading && <Loader loading={loading} />}
      <div
        className="big-row"
        style={{
          width: "100%",
          backgroundColor: "#fefefe",
        }}>
        <Row className="generic-row-main">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <Typography
              justifyContent="start"
              color="#000"
              variant={"h1"}
              fontSize={isSmallScreen ? 35 : 60}
              fontWeight={"bold"}>
              {t("News")}
            </Typography>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                padding: "3rem 0rem",
              }}>
              <FormControl sx={{ m: 1, minWidth: isSmallScreen ? 190 : 250 }}>
                <InputLabel id="category-selector">{t("Category")}</InputLabel>
                <Select
                  labelId="category-selector"
                  value={category}
                  label={t("Category")}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}>
                  <MenuItem value={""}>
                    <em>{t("All Categories")}</em>
                  </MenuItem>
                  {uniqueCategories.map((singleCategory) => (
                    <MenuItem key={singleCategory} value={`${singleCategory}`}>
                      {t(singleCategory)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Col>
        </Row>

        <div
          style={{
            backgroundColor: "#d7d7d7",
            padding: "5% 0rem",
            width: "100%",
          }}>
          <Row className="generic-row-main" style={{ width: "auto" }}>
            {Array.isArray(data) ? (
              data
                ?.filter(
                  (singleNew) =>
                    category === "" || singleNew.category === category
                )
                .map((singleNew, index) => (
                  <Col
                    xxl={4}
                    xl={6}
                    lg={6}
                    md={12}
                    xs={12}
                    key={index}
                    style={{ padding: "1rem " }}>
                    <Card key={nanoid()} style={{ minHeight: "100%" }}>
                      <Typography
                        align="left"
                        color="#808080"
                        variant={"h6"}
                        padding={"3rem 3rem 1rem 3rem"}>
                        {formatDate(singleNew.createdAt)}
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "1rem 3rem 1rem 3rem",
                          height: "150px",
                        }}>
                        <Typography
                          align="left"
                          color="#000000"
                          fontSize={21}
                          variant={"h2"}
                          fontWeight={"bold"}>
                          {singleNew && singleNew[i18n.language + "Title"]}
                        </Typography>
                        {/* <div
                            className="parent"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}>
                            <p style={{ color: "black" }}>
                              <ScaleText>{singleNew.title}</ScaleText>
                            </p>
                          </div>
                         */}
                      </div>

                      <LinkContainer
                        to={`/news/${singleNew._id}`}
                        className="link-new">
                        <div className="container-image-category-link">
                          <div
                            className="image-news-card"
                            style={{
                              padding: "1rem 3rem 1rem 3rem",
                            }}>
                            <img
                              src={`${singleNew.image}`}
                              alt="New's Image"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                borderRadius: "20px",
                              }}
                            />
                          </div>
                          <Row style={{ paddingBottom: "1rem" }}>
                            <Col>
                              <Typography
                                align="left"
                                color="#000"
                                fontSize={10}
                                variant={"overline"}
                                padding={"0rem 0rem 0rem 3rem"}
                                fontWeight={"bold"}>
                                {t(singleNew.category)}
                              </Typography>
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                justifyContent: "right",
                                paddingRight: "4rem",
                              }}>
                              <Typography
                                align="left"
                                color="#000"
                                variant={"overline"}
                                padding={"0.3rem 0.5rem 0rem 0rem"}
                                fontWeight={"bold"}>
                                {t("Read More")}
                              </Typography>
                              <EastIcon
                                style={{
                                  fontSize: 40,
                                  color: "black",
                                  margin: "0",
                                  padding: "0px",
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </LinkContainer>
                    </Card>
                  </Col>
                ))
            ) : (
              <Typography
                align="center"
                color="#ffffff"
                variant={"h2"}
                padding={"3rem 3rem 3rem 3rem"}>
                {t("NO NEWS TO DISPLAY")}
              </Typography>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default News;
