import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import VideoPlayer from "../../components/HomePage/VideoPlayer";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@mui/material";
import MemoryIcon from "@mui/icons-material/Memory";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedData,
  fetchServiceData,
  fetchServiceIOSVideo,
} from "../../feature/serviceContext/serviceContextSlice";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import axios from "axios";

function Service(props) {
  const { serviceName } = useParams();
  const [isMobile, setIsMobile] = useState(false);

  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const isMediumScreen = useMediaQuery("(max-width:1000px)");
  const isLargeScreen = useMediaQuery("(min-width:1700px)");
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    contextData: service,
    featuresData: features,
    iOSVideo,
    selectedData: selectedFeature,
    descSelectedData: description,
    loading,
    error,
  } = useSelector((state) => state.serviceContext);

  useEffect(() => {
    if (isIOS) {
      dispatch(fetchServiceIOSVideo(serviceName));
    }
    dispatch(fetchServiceData(serviceName));
    const tempIsMobile = /Mobi|Android/i.test(navigator.userAgent);
    setIsMobile(tempIsMobile);
  }, [serviceName]);

  if (error) {
    navigate("*");
  }

  return (
    <div style={{ padding: "0rem", margin: "0rem", width: "100%" }}>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div>
          {" "}
          <div
            style={{
              backgroundColor: isMediumScreen ? "#ffffff" : "#dde6ed",
              position: "relative",
            }}>
            <VideoPlayer url={isIOS ? iOSVideo : service.video} />

            <div
              className="container-above-video"
              style={{
                ...(isMediumScreen
                  ? { padding: "2rem 0rem 3rem 0rem" }
                  : {
                      position: "absolute",
                      top: "49.55%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "100%",
                      height: "99.1%",
                      zIndex: 1,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }),

                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                className="title"
                color={isMediumScreen ? "#212165" : "#fff"}
                variant="h1"
                align="center"
                fontWeight="bold"
                padding={isMediumScreen ? "2rem" : ""}
                fontSize={isMediumScreen ? 30 : 45}>
                {service?.firstTitle && (
                  <>
                    {t(service.firstTitle.split(":")[0])}:
                    <br />
                    {t(service.firstTitle.split(":")[1])}
                  </>
                )}
              </Typography>

              <Typography
                className="description"
                color={isMediumScreen ? "#212165" : "#fff"}
                fontWeight="bold"
                padding={isMediumScreen ? "2rem 3rem 4rem 3rem" : ""}
                align="center">
                {t(service.descFirstTitle)}
              </Typography>
              <Card
                className="card-video"
                style={{
                  backgroundColor: "#ed5034",
                  maxWidth: isSmallScreen ? "300px" : "400px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: isSmallScreen ? "0.5rem" : "1rem",
                }}>
                <MemoryIcon
                  className="icon"
                  style={{
                    fontSize: isSmallScreen ? 40 : 60,
                    color: "white",
                    margin: "0",
                    padding: "0rem",
                  }}
                />
                <Typography
                  className="motto"
                  color="#fff"
                  variant="h2"
                  fontWeight="bold"
                  padding="0.5rem"
                  align="center"
                  fontSize={
                    isLargeScreen ? "25px" : isSmallScreen ? "16px" : "20px"
                  }>
                  {t(service.motto)}
                </Typography>
              </Card>
            </div>
          </div>
          <Row
            style={{
              width: "100%",
              margin: "0",
              padding: "4rem 1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#dde6ed",
            }}>
            <Row className="generic-row-main" style={{ width: "100%" }}>
              <Typography
                color="#212165"
                variant="h3"
                align="center"
                fontWeight={"bold"}
                padding={"2rem"}
                fontSize={30}>
                {t(service?.secondTitle)}
              </Typography>
              <Row className="services-feature-key-row-switch">
                {!isMobile && (
                  <Col
                    xxl={features.length > 4 ? 7 : 6}
                    xl={features.length > 4 ? 12 : 6}
                    lg={features.length > 4 ? 12 : 6}
                    md={12}
                    sm={12}
                    style={{
                      padding: "0rem",
                      margin: "0rem",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5rem",
                        margin: "0rem",
                      }}>
                      {features && (
                        <Row className="services-feature-key-row-switch">
                          {Object.values(features).map((feature) => (
                            <Col
                              lg={features.length > 4 ? 6 : 12}
                              md={6}
                              sm={12}
                              xs={12}
                              key={feature._id}
                              style={{
                                padding: "0rem 0.5rem 1rem 0.5rem ",
                                margin: "0rem",
                                maxHeight: "100%",
                              }}>
                              <Card
                                style={{
                                  height: "100%",
                                  margin: "auto",
                                  borderRadius: "50px",
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}>
                                  <Typography
                                    color="#212165"
                                    variant="h5"
                                    align="left"
                                    fontWeight={"bold"}
                                    fontSize={20}
                                    padding={"2rem 0rem 0rem 2rem"}
                                    style={{ wordBreak: "break-word" }}>
                                    {t(feature.title)}
                                  </Typography>{" "}
                                  <div className="feature-switch">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={selectedFeature === feature}
                                        onChange={() => {
                                          dispatch(changeSelectedData(feature));
                                        }}
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </div>
                                </div>

                                <Typography
                                  color="#212165"
                                  align="left"
                                  padding={"1rem 2rem 1.5rem 2rem"}>
                                  {t(feature.smallDescription)}
                                </Typography>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </Row>
                  </Col>
                )}

                <Col
                  style={{
                    padding: isSmallScreen
                      ? "2rem 0rem 0rem 0rem"
                      : "3rem 3rem 0rem 3rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {isMobile && (
                    <FormControl
                      sx={{
                        m: 3,
                        maxWidth: 280,
                      }}>
                      <InputLabel id="service-feature-selector">
                        {t("Features")}
                      </InputLabel>
                      <Select
                        labelId="service-feature-selector"
                        className="service-feature-select"
                        value={selectedFeature}
                        label={t("Features")}
                        onChange={(e) => {
                          dispatch(changeSelectedData(e.target.value));
                        }}>
                        {Object.values(features).map((feature) => (
                          <MenuItem key={feature._id} value={feature}>
                            {t(feature.title)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {selectedFeature !== undefined && (
                    <div>
                      <Typography
                        variant="h3"
                        color="#394867"
                        align="center"
                        fontSize={35}
                        padding={"1rem"}>
                        {t(selectedFeature.title)}
                      </Typography>
                      {description.length > 0 && (
                        <Typography
                          color="#394867"
                          padding={"1rem 1rem 2rem 1rem"}>
                          {t(description[0])}
                        </Typography>
                      )}
                    </div>
                  )}

                  {selectedFeature !== undefined &&
                    selectedFeature.serviceFeatureKeys !== undefined && (
                      <ul>
                        {Object.values(selectedFeature.serviceFeatureKeys).map(
                          (benefit, index) => (
                            <li
                              key={index}
                              style={{
                                color: "#394867",
                                paddingBottom: "1rem",
                              }}>
                              <strong>{t(benefit.title)}</strong>
                              <br />
                              {t(benefit.description)}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  {description !== undefined && description.length > 1 ? (
                    description.map((text, index) => {
                      if (index !== 0) {
                        return (
                          <div key={index}>
                            <Typography
                              color="#394867"
                              padding="1rem 1rem 1rem 1rem"
                              align="left">
                              {t(text)}
                            </Typography>
                          </div>
                        );
                      }
                      return null;
                    })
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Row>
          </Row>
          <Row
            style={{
              backgroundColor: "#0a0f17",
              padding: "2rem 0rem",
              margin: "0",
              width: "100%",
            }}>
            <Row className="generic-row-main" style={{ width: "fit-content" }}>
              <Typography
                variant="h3"
                color="#ffffff"
                align="center"
                fontWeight={"bold"}
                fontSize={35}
                padding={"3rem 0rem 2rem 0rem"}>
                {t(service.thirdTitle)}
              </Typography>
              <Typography
                variant="h5"
                color="#ffffff"
                fontSize={20}
                padding={isSmallScreen ? "1rem 1rem" : "1rem 3rem"}
                align="center">
                {t(service.descOneThirdTitle)}
              </Typography>
              <Typography
                color="#ffffff"
                variant="h5"
                fontSize={20}
                padding={isSmallScreen ? "2rem 1rem" : "2rem 3rem"}
                align="center">
                {t(service.descTwoThirdTitle)}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "3rem",
                }}>
                <button
                  className="navigate-contact-us-btn"
                  onClick={() => navigate("/contact-us")}>
                  {t("SCHEDULE A MEETING")}
                </button>
              </div>
            </Row>
          </Row>
        </div>
      )}
    </div>
  );
}

Service.propTypes = {};

export default Service;