import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import PropTypes from "prop-types";

//import partners from "../partners";
import VideoPlayer from "../components/HomePage/VideoPlayer";
import videoURL from "../assets/videos/ArtiHomePageBG.webm";
import "./HomeScreen.css";
import DataToResults from "../components/HomePage/DataToResults";
import ServicesSectionHomePage from "../components/HomePage/ServicesSectionHomePage";
import Products from "../components/HomePage/Products";
import Partners from "../components/HomePage/Partners";
import NewsletterSignUp from "../components/HomePage/NewsletterSignUp";
import LatestNews from "../components/HomePage/LatestNews";
import { useDispatch, useSelector } from "react-redux";
import SplashScreen from "../components/SplashScreen";
import { appearVideo } from "../feature/splashScreen/splashScreenSlice";
import { useCookies } from "react-cookie";
import axios from "axios";

function HomeScreen() {
  const [cookies, setCookie] = useCookies(["playedVideo"]);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [video, setVideo] = useState("");

  useEffect(() => {
    if (isIOS) {
      const fetchData = async () => {
        try {
          const response = await axios.get("api/video/home");
          const result = response.data.video;
          setVideo(result);
        } catch (error) {
          alert("Video doesn't exist");
        }
      };
      fetchData();
    }
  }, [isIOS]);

  return (
    <div>
      {cookies.playedVideo === undefined ? (
        <SplashScreen />
      ) : (
        <div className="home-screen">
          <div style={{ backgroundColor: "#0a0f17" }}>
            <VideoPlayer url={isIOS ? video : videoURL} />
          </div>

          <div className="results-section">
            <DataToResults />
          </div>

          <div className="services-section">
            <ServicesSectionHomePage />
          </div>

          <div className="products-section">
            <Products />
          </div>

          <div className="partners-section">
            <Partners />
          </div>
          <div className="news-section">
            <LatestNews />
          </div>
          <div className="newsletter-sing-up-section">
            <NewsletterSignUp />
          </div>
        </div>
      )}
    </div>
  );
}

HomeScreen.propTypes = {};

export default HomeScreen;