import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Typography, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedData,
  fetchProductData,
} from "../../feature/productContext/productContextSlice";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import ReactGA from "react-ga4";

function Product(props) {
  const { productName } = useParams();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    contextData: product,
    featuresData: features,
    selectedData: selectedFeature,
    descSelectedData: description,
    loading,
    error,
  } = useSelector((state) => state.productContext);

  useEffect(() => {
    dispatch(fetchProductData(productName));
  }, [productName]);

  if (error) {
    navigate("*");
  }

  useEffect(() => {
    ReactGA.initialize("G-ZNBTFWN36W");
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: `Product ${productName}`,
    });
    console.log("Location: ", location);
  });

  return (
    <div>
      <div className={"product-page"}>
        <Row style={{ padding: "0rem ", margin: "0rem", width: "100%" }}>
          {loading && <Loader loading={loading} />}
          <div style={{ padding: "0rem ", margin: "0rem", width: "100%" }}>
            <div
              style={{
                padding: "2rem",
                borderBottom: "1px solid #98a0b0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img
                className="submenu-img-top"
                style={{ height: "16vh" }}
                src={`${product?.product_id?.image}`}
                alt={`${product?.product_id?.name} Logo`}
              />
              <Typography
                color="#212165"
                variant="h1"
                fontWeight={"bold"}
                padding={"1rem"}
                fontSize={35}>
                {t(product?.product_id?.name)} - {t(product?.firstTitle)}
              </Typography>
            </div>

            <Row
              className="generic-row-main"
              style={{
                width: "fit-content",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Col lg={5} md={12} sm={12} style={{ padding: "2rem 0rem" }}>
                <Typography
                  color="#212165"
                  variant="h2"
                  paddingBottom={"2rem"}
                  fontWeight={"bold"}
                  align="center"
                  fontSize={25}>
                  {t(product.secondTitle)}
                </Typography>
                <Typography
                  color="#212165"
                  variant="h6"
                  align="center"
                  fontSize={18}>
                  {t(product?.descSecondTitle)}
                </Typography>
              </Col>
              <Col>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    padding: "1rem",
                  }}>
                  <img
                    src={product.demoImage}
                    alt={`Showcase of ${product.name}`}
                    style={{
                      width: "80%",
                      maxHeight: "80%",
                      marginBottom: "5%",
                      marginTop: "5%",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "#0a0f17",
                padding: "3rem 0rem",
                margin: "0",
              }}>
              <Row
                className="generic-row-main"
                style={{ width: "fit-content" }}>
                <Typography
                  variant="h2"
                  color="#fff"
                  align="center"
                  fontSize={25}
                  fontWeight={"bold"}
                  padding={"1rem"}>
                  {t(product.thirdTitle)}
                </Typography>
                <Typography
                  variant="h6"
                  color="#fff"
                  align="center"
                  padding={"1rem"}
                  fontSize={18}>
                  {t(product.descThirdTitle)}
                </Typography>
              </Row>
            </Row>

            <Row
              style={{
                backgroundColor: "white",
                padding: "2rem 0rem",
                margin: "0",
              }}>
              <Row
                className="generic-row-main"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                }}>
                <Typography
                  variant="h2"
                  color="#394867"
                  align="center"
                  fontSize={25}
                  fontWeight={"bold"}
                  padding={"3rem 0rem 2rem 0rem"}>
                  {t("Key Features and Benefits")}
                </Typography>

                <Row style={{ padding: "2rem 0rem" }}>
                  <Col lg={6} md={12} sm={12}>
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0",
                        margin: "0",
                      }}>
                      {features && features.length > 0 ? (
                        features.toReversed().map((ic) => (
                          <div
                            className="selected-icons-product-circle"
                            style={{
                              width: "235px",
                              height: "235px",
                              borderRadius: "50%",
                              margin: isSmallScreen ? "0rem" : "0.5rem",
                              overflow: "hidden",
                              border:
                                selectedFeature.title === ic.title
                                  ? "5px solid #fd3a25"
                                  : "none",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            key={ic.title}>
                            <div
                              className="icons-product-circle"
                              style={{
                                width: "200px",
                                height: "200px",
                                borderRadius: "50%",
                                overflow: "hidden",
                                border: "3px solid #394867",
                              }}
                              key={ic.title}
                              onClick={() => dispatch(changeSelectedData(ic))}>
                              <img
                                src={ic.image}
                                alt={`${ic.title} image`}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ color: "black" }}>
                          {t("No features available.")}
                        </p>
                      )}
                    </Row>
                  </Col>

                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    {Object.values(features)
                      .filter((item) => item.title === selectedFeature.title)
                      .map((feature) => {
                        return (
                          <div key={feature}>
                            <Typography
                              variant="h2"
                              color="#394867"
                              align="center"
                              fontSize={30}
                              padding={"1rem 0rem"}>
                              {t(feature.title)}
                            </Typography>

                            <Typography color="#394867" paddingBottom={"1rem"}>
                              {t(feature.description)}
                            </Typography>
                          </div>
                        );
                      })}

                    <Typography
                      color="#394867"
                      fontWeight={"bold"}
                      padding={"1rem 0rem 2rem 0rem"}>
                      {t("Key Benefits:")}
                    </Typography>
                    {selectedFeature !== undefined &&
                      selectedFeature.productFeatureKeys !== undefined && (
                        <ul>
                          {Object.values(
                            selectedFeature.productFeatureKeys
                          ).map((benefit, index) => (
                            <li
                              key={index}
                              style={{
                                color: "#394867",
                                paddingBottom: "1rem",
                              }}>
                              <strong>{t(benefit.title)}</strong>{" "}
                              {t(benefit.description)}
                            </li>
                          ))}
                        </ul>
                      )}
                  </Col>
                </Row>
              </Row>
            </Row>
            <Row
              style={{
                backgroundColor: "#0a0f17",
                padding: "2rem 0rem",
                margin: "0",
              }}>
              <Row
                className="generic-row-main"
                style={{ width: "fit-content" }}>
                <Typography
                  variant="h2"
                  color="#ffffff"
                  align="center"
                  fontWeight={"bold"}
                  padding={"3rem 0rem 2rem 0rem"}
                  fontSize={35}>
                  {t(product.fourthTitle)}
                </Typography>
                <Typography
                  variant="h5"
                  color="#ffffff"
                  padding={isSmallScreen ? "1rem 1rem" : "1rem 4rem"}
                  align="center"
                  fontSize={20}>
                  {t(product.descOneFourthTitle)}
                </Typography>
                <Typography
                  color="#ffffff"
                  variant="h5"
                  padding={isSmallScreen ? "2rem 1rem" : "2rem 4rem"}
                  align="center"
                  fontSize={20}>
                  {t(product.descTwoFourthTitle)}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "1rem",
                  }}>
                  <button
                    className="navigate-contact-us-btn"
                    onClick={() => navigate("/contact-us")}>
                    {t("REQUEST A DEMO")}
                  </button>
                </div>
              </Row>
            </Row>
          </div>
        </Row>
      </div>
    </div>
  );
}

Product.propTypes = {};

export default Product;
