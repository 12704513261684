import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";

import PropTypes from "prop-types";
import { Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

function Partner({ partner }) {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width:767px)");

  return (
    <a
      href={partner.url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}>
      <Card
        className="my-3 p-3 rounded"
        style={{
          backgroundColor: "transparent",
          border: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",

          ...(isSmallScreen
            ? { minWidth: "200px", height: "300px", marginRight: "0.5rem" }
            : {
                height: "380px",

                width: "300px",
                marginRight: "2rem",
                marginLeft: "0.5rem",
              }),
        }}>
        <Card.Body>
          <Card.Img
            className="card-img-top"
            src={partner.image}
            style={{ height: "70%" }}
          />
          <Card.Title className="card-title-center" as="div">
            <Typography variant="h5" color={"#000000"} fontWeight={"bold"}>
              {t(partner.name)}
            </Typography>
            <Typography
              variant="caption"
              color={"#808080"}
              fontWeight={"bold"}
              fontSize={"15px"}>
              {t(partner.country)}
            </Typography>
          </Card.Title>
        </Card.Body>
      </Card>
    </a>
  );
}

Partner.propTypes = {};

export default Partner;
