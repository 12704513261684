import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const submitContactUs = createAsyncThunk(
  "contactUs/submit",
  async (formData) => {
    const csrftoken = Cookies.get("csrftoken");

    const axiosInstance = axios.create({
      headers: {
        "X-CSRFToken": csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    try {
      const response = await axiosInstance.post("/api/contact/", formData);
      const responseData = response.data;
      return responseData;
    } catch (error) {
      throw error;
    }
  }
);

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState: {
    message: null,
    isLoading: false,
    success: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitContactUs.pending, (state) => {
        state.isLoading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(submitContactUs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(submitContactUs.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.error.message;
      });
  },
});

export default contactUsSlice.reducer;
