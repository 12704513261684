import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/", maxAge: 31536000 });
  };

  return (
    <div
      className="cookie-consent"
      style={{
        position: "fixed",
        bottom: "1rem",
        left: "1rem",
        backgroundColor: "black",
        width: "375px",
        height: "min-content",
        padding: "2rem",
        borderRadius: "30px",
        border: "3mm ridge white",
        zIndex: "9999",
      }}>
      <Typography variant="body1" color={"white"} paddingBottom={"0.5rem"}>
        We use cookies to enhance your user experience. By using our website,
        you agree to our use of cookies.{" "}
        <Link style={{ color: "#1bfd9c" }} to={"/cookie-policy"}>
          Learn more.
        </Link>
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}>
        <Button
          variant="contained"
          style={{ backgroundColor: "#ffa07a" }}
          onClick={giveCookieConsent}>
          Accept
        </Button>
      </div>
    </div>
  );
};

export default CookieConsent;
