import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchServiceData = createAsyncThunk(
  "serviceData/fetch",
  async (serviceName) => {
    try {
      const contextResponse = await axios.get(
        `/api/serviceContext/${serviceName}`
      );
      const featuresResponse = await axios.get(
        `/api/serviceFeatures/${serviceName}`
      );
      const contextData = contextResponse.data;
      const featuresData = featuresResponse.data;
      return { contextData, featuresData };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchServiceIOSVideo = createAsyncThunk(
  "serviceIOSVideo/fetch",
  async (videoName) => {
    try {
      const iOSVideoResponse = await axios.get(`/api/video/${videoName}`);
      const iOSVideoData = iOSVideoResponse.data;
      return { iOSVideoData };
    } catch (error) {
      throw error;
    }
  }
);


const serviceContextSlice = createSlice({
  name: "serviceContext",
  initialState: {
    contextData: {},
    featuresData: [],
    selectedData: {},
    iOSVideo: null,
    descSelectedData: [],
    loading: false,
    error: null,
  },
  reducers: {
    changeSelectedData: (state, action) => {
      state.selectedData = action.payload;
      const splitSections = (str) => str.split("--");
      state.descSelectedData = splitSections(state.selectedData.description);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceData.fulfilled, (state, action) => {
        state.loading = false;
        state.contextData = action.payload.contextData;
        state.featuresData = action.payload.featuresData;
        state.selectedData = action.payload.featuresData[0];
        const splitSections = (str) => str.split("--");
        state.descSelectedData = splitSections(state.selectedData.description);
      })
      .addCase(fetchServiceData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchServiceIOSVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceIOSVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.iOSVideo = action.payload.iOSVideoData.video;
      })
      .addCase(fetchServiceIOSVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { changeSelectedData } = serviceContextSlice.actions;

export default serviceContextSlice.reducer;